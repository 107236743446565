import { SessionUser } from "../utils/SessionUser";
import { doFetch } from "./doFetch";
import { RestRoutes } from "../constants/restRoutes";
import { Identifier } from "react-admin";

export class EditProvider {
    private readonly sessionUser: SessionUser;

    constructor(sessionUser: SessionUser) {
        this.sessionUser = sessionUser;
    }

    private static getApiUrlBiens(idProgramme: string | number, plaquetteCommerciale: string) {
        return (
            process.env.REACT_APP_API_ENDPOINT + "/" + RestRoutes.biens + "/" + idProgramme + "/" + plaquetteCommerciale
        );
    }

    async fetchDefiscalisations(errorFallback: (status: number) => void) {
        return await doFetch(this.sessionUser, RestRoutes.getDefiscalisations, errorFallback);
    }

    async uploadPlaquetteCommerciale(
        idProgramme: Identifier,
        plaquetteCommercialeParameter: { value: string },
        errorFallback: (status: number) => void
    ) {
        return await this.callBienApi(
            EditProvider.getApiUrlBiens(idProgramme, RestRoutes.plaquetteCommerciale),
            "POST",
            JSON.stringify(plaquetteCommercialeParameter),
            errorFallback
        );
    }

    async deletePlaquetteCommerciale(idProgramme: Identifier, errorFallback: (status: number) => void) {
        return await this.callBienApi(
            EditProvider.getApiUrlBiens(idProgramme, RestRoutes.plaquetteCommerciale),
            "DELETE",
            null,
            errorFallback
        );
    }

    private async callBienApi(
        url: string,
        method: string,
        body: string | null,
        errorFallback: (status: number) => void
    ) {
        const response = await fetch(
            new Request(url, {
                method: method,
                body: body,
                headers: this.sessionUser.getAuthorizationHeaderWithIdClient(),
            })
        );

        if (!response.ok) {
            const status = response.status;
            errorFallback(status);
        }

        return await response.json();
    }

    async fetchCP(coPays: string, inputCPValue: string, errorFallback: (status: number) => void) {
        const route =
            RestRoutes.pays +
            "/" +
            coPays +
            "/" +
            RestRoutes.codesPostaux +
            "?" +
            new URLSearchParams({
                code: inputCPValue,
            });
        return await doFetch(this.sessionUser, route, errorFallback);
    }

    async fetchCommunes(coPays: string, inputVilleValue: string, errorFallback: (status: number) => void) {
        const route =
            RestRoutes.pays +
            "/" +
            coPays +
            "/" +
            RestRoutes.communes +
            "?" +
            new URLSearchParams({
                coLangue: this.sessionUser.langue,
                libelle: inputVilleValue,
            });
        return await doFetch(this.sessionUser, route, errorFallback);
    }
}
