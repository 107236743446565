import { DesactiverProgrammesHandlers } from "../DesactiverProgrammesHandlers";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Confirm } from "react-admin";
import * as React from "react";

export const DesactivationItem = (props: {
    onClick: () => void;
    primary: string;
    open: boolean;
    loading: boolean;
    title: string;
    content: string;
    desactiverProgrammesHandlers: DesactiverProgrammesHandlers;
}) => (
    <MenuItem onClick={props.onClick}>
        <ListItemText primary={props.primary} />
        <Confirm
            isOpen={props.open}
            loading={props.loading}
            title={props.title}
            content={props.content}
            onConfirm={props.desactiverProgrammesHandlers.handleConfirm}
            onClose={props.desactiverProgrammesHandlers.handleDialogClose}
        />
    </MenuItem>
);
