import React from "react";
import { required, TextInput, useTranslate } from "react-admin";
import { useStyles } from "../../biens/formStyles";

const DescriptionLotTab = () => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <>
            <TextInput
                multiline
                rows={10}
                className={classes.largeField}
                label={translate("lipro.edit_lots_label.description.title")}
                source="descriptifWeb"
                validate={required()}
            />
        </>
    );
};

export default DescriptionLotTab;
