import React from "react";
import { BooleanInput, NumberInput, SelectArrayInput, SelectInput, TextInput, useTranslate } from "react-admin";
import { useStyles } from "../formStyles";
import { Grid } from "@material-ui/core";
import UsageLogementChoices from "../../../model/constants/usageLogementChoices";
import TypeLogementChoices from "../../../model/constants/typeLogementChoices";
import TypeChauffageChoices from "../../../model/constants/typeChauffageChoices";
import dpeHabitationChoices from "../../../model/constants/dpeHabitationChoices";
import TypeLabelChoices from "../../../model/constants/typeLabelChoices";
import DefiscalisationInput from "../widgets/DefiscalisationInput";
import DPEField from "../widgets/DPEField";

const listToString = (list: string[]) => {
    let string = "";
    if (list.length > 0) {
        for (let i = 0; i < list.length - 1; i++) {
            string += list[i] + ",";
        }
        string += list[list.length - 1];
    }
    return string;
};

const stringToList = (string: string) => {
    return string ? string.split(",") : [];
};

const InfosComplementairesTab = () => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <>
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_label.infos_complementaires.secteur")}
                source="localisation"
            />
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_label.infos_complementaires.proximite")}
                source="proximite"
            />
            <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                <SelectArrayInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.usage_logement.title")}
                    source="usageLgt"
                    format={stringToList}
                    parse={listToString}
                    translateChoice
                    allowEmpty
                    choices={UsageLogementChoices}
                />
                <SelectArrayInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.type_logement.title")}
                    source="typeLgt"
                    format={stringToList}
                    parse={listToString}
                    translateChoice
                    allowEmpty
                    choices={TypeLogementChoices}
                />
            </Grid>
            <DefiscalisationInput
                label={translate("lipro.edit_label.infos_complementaires.defiscalisation.title")}
                source="defisc"
                className={classes.field}
            />
            <br />
            <SelectArrayInput
                className={classes.field}
                label={translate("lipro.edit_label.infos_complementaires.type_chauffage.title")}
                source="typeChauffage"
                format={stringToList}
                parse={listToString}
                translateChoice
                allowEmpty
                choices={TypeChauffageChoices}
            />
            <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                <SelectInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.dpe.title")}
                    source="dpe"
                    translateChoice
                    allowEmpty
                    optionText={<DPEField />}
                    choices={dpeHabitationChoices}
                />
                <NumberInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.valeur_energie")}
                    source="valeurDpe"
                />
            </Grid>
            <SelectArrayInput
                className={classes.field}
                label={translate("lipro.edit_label.infos_complementaires.type_label.title")}
                source="typeLabel"
                format={stringToList}
                parse={listToString}
                translateChoice
                allowEmpty
                choices={TypeLabelChoices}
            />
            <br />
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.piscine")}
                    source="piscine"
                />
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.ascenseur")}
                    source="ascenseur"
                />
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.acces_handicape")}
                    source="accesHandicape"
                />
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.interphone")}
                    source="interphone"
                />
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.visiophone")}
                    source="visiophone"
                />
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.digicode")}
                    source="digicode"
                />
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.gardien")}
                    source="gardien"
                />
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.jardin")}
                    source="jardin"
                />
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.garage")}
                    source="garage"
                />
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.box")}
                    source="box"
                />
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.edit_label.infos_complementaires.parking")}
                    source="parking"
                />
            </Grid>
            <TextInput
                multiline
                rows={5}
                className={classes.largeField}
                label={translate("lipro.edit_label.infos_complementaires.texte_complementaire")}
                source="texteComplementaire"
            />
        </>
    );
};
export default InfosComplementairesTab;
