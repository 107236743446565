import * as React from "react";
import { Children, cloneElement, FC, isValidElement, ReactElement, useRef } from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { Record, useTranslate, ValidationError } from "ra-core";
import classNames from "classnames";
import { FieldArrayRenderProps } from "react-final-form-arrays";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { FormInput } from "react-admin";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: 0,
            marginBottom: 0,
            "& > li:last-child": {
                borderBottom: "none",
            },
        },
        line: {
            display: "flex",
            alignItems: "center",
            listStyleType: "none",
            borderBottom: `solid 1px ${theme.palette.divider}`,
            [theme.breakpoints.down("xs")]: { display: "block" },
            "&.fade-enter": {
                opacity: 0.01,
                transform: "translateX(100vw)",
            },
            "&.fade-enter-active": {
                opacity: 1,
                transform: "translateX(0)",
                transition: "all 500ms ease-in",
            },
            "&.fade-exit": {
                opacity: 1,
                transform: "translateX(0)",
            },
            "&.fade-exit-active": {
                opacity: 0.01,
                transform: "translateX(100vw)",
                transition: "all 500ms ease-in",
            },
        },
        index: {
            width: "3em",
            [theme.breakpoints.down("sm")]: { display: "none" },
        },
        form: {
            flex: 2,
        },
        action: {
            marginTop: "0.5em",
        },
        upDownButtons: {
            display: "grid",
            placeItems: "center",
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: "RaSimpleFormIterator" }
);

// @ts-ignore
const DefaultAddButton = (props) => {
    const classes = useStyles(props);
    const translate = useTranslate();
    return (
        <Button size="small" {...props}>
            <AddIcon className={classes.leftIcon} />
            {translate("ra.action.add")}
        </Button>
    );
};

// @ts-ignore
const DefaultRemoveButton = (props) => {
    const classes = useStyles(props);
    const translate = useTranslate();
    console.log(props);
    return (
        <Button size="small" {...props}>
            <CloseIcon className={classes.leftIcon} />
            {translate("ra.action.remove")}
        </Button>
    );
};

const UpDownButtons = ({
    index,
    max,
    onClick,
    disabled,
}: {
    index: number;
    max?: number;
    onClick: (index: number, moveTo: number) => void;
    disabled: boolean;
}) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <div className={classes.upDownButtons}>
            <Tooltip title={disabled ? translate("lipro.edit_label.galerie_photos.tooltip_reorder") : ""} arrow>
                <div>
                    <IconButton
                        color="primary"
                        size="small"
                        onClick={() => onClick(index, index - 1)}
                        disabled={disabled || index <= 0}
                        className={classes.leftIcon}
                    >
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                        color="primary"
                        size="small"
                        onClick={() => onClick(index, index + 1)}
                        disabled={disabled || max == null || index >= max - 1}
                        className={classes.leftIcon}
                    >
                        <ArrowDownwardIcon />
                    </IconButton>
                </div>
            </Tooltip>
        </div>
    );
};

const ReorderedFormIterator: FC<SimpleFormIteratorProps> = (props) => {
    const {
        addButton = <DefaultAddButton />,
        removeButton = <DefaultRemoveButton />,
        basePath,
        children,
        className,
        fields,
        // @ts-ignore
        meta: { error, submitFailed },
        record,
        resource,
        source,
        disabled,
        disableAdd,
        disableRemove,
        disableReordering,
        disableReorderingOption,
        variant,
        margin,
        TransitionProps,
        defaultValue,
    } = props;
    const classes = useStyles(props);
    const nodeRef = useRef(null);

    // We need a unique id for each field for a proper enter/exit animation
    // so we keep an internal map between the field position and an auto-increment id
    const nextId = useRef(fields && fields.length ? fields.length : defaultValue ? defaultValue.length : 0);

    // We check whether we have a defaultValue (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    const ids = useRef(nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []);

    const removeField = (index: number) => () => {
        ids.current.splice(index, 1);
        fields?.remove(index);
    };

    // Returns a boolean to indicate whether to disable the remove button for certain fields.
    // If disableRemove is a function, then call the function with the current record to
    // determining if the button should be disabled. Otherwise, use a boolean property that
    // enables or disables the button for all of the fields.
    const disableRemoveField = (record: Record, disableRemove: boolean | DisableRemoveFunction) => {
        if (typeof disableRemove === "boolean") {
            return disableRemove;
        }
        return disableRemove && disableRemove(record);
    };

    const addField = () => {
        ids.current.push(nextId.current++);
        fields?.push(undefined);
    };

    // add field and call the onClick event of the button passed as addButton prop
    const handleAddButtonClick = (originalOnClickHandler: any) => (event: React.MouseEvent) => {
        addField();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    };

    // remove field and call the onClick event of the button passed as removeButton prop
    const handleRemoveButtonClick = (originalOnClickHandler: any, index: number) => (event: React.MouseEvent) => {
        removeField(index)();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    };

    const handleUpDown = (index: number, moveTo: number) => {
        const tmp = ids.current[index];
        ids.current[index] = ids.current[moveTo];
        ids.current[moveTo] = tmp;
        fields?.move(index, moveTo);
    };

    const records = get(record, source!);
    return fields ? (
        <ul className={classes.root}>
            {submitFailed && typeof error !== "object" && error && (
                <FormHelperText error>
                    <ValidationError error={error as string} />
                </FormHelperText>
            )}
            <TransitionGroup component={null}>
                {fields.map((member, index) => (
                    <CSSTransition nodeRef={nodeRef} key={index} timeout={500} classNames="fade" {...TransitionProps}>
                        <li className={classes.line}>
                            <Typography variant="body1" className={classes.index}>
                                {index + 1}
                            </Typography>
                            {!disabled && !disableReorderingOption && (
                                <UpDownButtons
                                    index={index}
                                    max={fields.length}
                                    onClick={handleUpDown}
                                    disabled={disableReordering || false}
                                />
                            )}
                            <section className={classNames(classes.form, className)}>
                                {Children.map(
                                    // @ts-ignore
                                    children,
                                    (input: ReactElement, index2) =>
                                        isValidElement<any>(input) ? (
                                            <FormInput
                                                basePath={input.props.basePath || basePath}
                                                input={cloneElement(input, {
                                                    source: input.props.source
                                                        ? `${member}.${input.props.source}`
                                                        : member,
                                                    index: input.props.source ? undefined : index2,
                                                    label:
                                                        typeof input.props.label === "undefined"
                                                            ? input.props.source
                                                                ? `resources.${resource}.fields.${input.props.source}`
                                                                : undefined
                                                            : input.props.label,
                                                    disabled,
                                                })}
                                                record={(records && records[index]) || {}}
                                                resource={resource}
                                                variant={variant}
                                                margin={margin}
                                            />
                                        ) : null
                                )}
                            </section>
                            {!disabled &&
                                !disableRemoveField(
                                    (records && records[index]) || {},
                                    // @ts-ignore
                                    disableRemove
                                ) && (
                                    <span className={classes.action}>
                                        {cloneElement(removeButton, {
                                            onClick: handleRemoveButtonClick(removeButton.props.onClick, index),
                                            className: classNames("button-remove", `button-remove-${source}-${index}`),
                                        })}
                                    </span>
                                )}
                        </li>
                    </CSSTransition>
                ))}
            </TransitionGroup>
            {!disabled && !disableAdd && (
                <li className={classes.line}>
                    <span className={classes.action}>
                        {cloneElement(addButton, {
                            onClick: handleAddButtonClick(addButton.props.onClick),
                            className: classNames("button-add", `button-add-${source}`),
                        })}
                    </span>
                </li>
            )}
        </ul>
    ) : null;
};

ReorderedFormIterator.defaultProps = {
    disableAdd: false,
    disableRemove: false,
    disableReordering: false,
    disableReorderingOption: false,
};

ReorderedFormIterator.propTypes = {
    defaultValue: PropTypes.any,
    addButton: PropTypes.element,
    removeButton: PropTypes.element,
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    // @ts-ignore
    fields: PropTypes.object,
    meta: PropTypes.object,
    // @ts-ignore
    record: PropTypes.object,
    source: PropTypes.string,
    resource: PropTypes.string,
    translate: PropTypes.func,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    disableReordering: PropTypes.bool,
    disableReorderingOption: PropTypes.bool,

    TransitionProps: PropTypes.shape({}),
};

type DisableRemoveFunction = (record: Record) => boolean;

export interface SimpleFormIteratorProps extends Partial<Omit<FieldArrayRenderProps<any, HTMLElement>, "meta">> {
    addButton?: ReactElement;
    basePath?: string;
    className?: string;
    defaultValue?: any;
    disabled?: boolean;
    disableAdd?: boolean;
    disableRemove?: boolean | DisableRemoveFunction;
    disableReordering?: boolean;
    disableReorderingOption?: boolean;
    margin?: "none" | "normal" | "dense";
    meta?: {
        // the type defined in FieldArrayRenderProps says error is boolean, which is wrong.
        error?: any;
        submitFailed?: boolean;
    };
    record?: Record;
    removeButton?: ReactElement;
    resource?: string;
    source?: string;
    TransitionProps?: any;
    variant?: "standard" | "outlined" | "filled";
}

export default ReorderedFormIterator;
