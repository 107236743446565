const NatureChauffageChoices = [
    {
        id: "Individuel",
        name: "lipro.edit_lots_label.caract_complementaires.nature_chauffage.individuel",
    },
    {
        id: "Collectif",
        name: "lipro.edit_lots_label.caract_complementaires.nature_chauffage.collectif",
    },
];

export default NatureChauffageChoices;
