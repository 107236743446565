import React from "react";
import { CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import LoginFromSSO from "./components/authentication/LoginFromSSO";

const customRoutes: CustomRoutes | undefined = [
    <Route exact noLayout path="/sso/:idSso" render={() => <LoginFromSSO />} />,
];

export default customRoutes;
