import * as React from "react";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";

export const DiffuserButton = (props: {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    label: string;
}) => (
    <Button onClick={props.onClick} variant="contained" color="primary">
        <PublishIcon fontSize="small" />
        &nbsp;
        {props.label}
    </Button>
);
