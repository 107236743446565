import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import UpdateIcon from "@material-ui/icons/Update";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";
import ScheduleIcon from "@material-ui/icons/Schedule";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import {
    CST_STATUT_DIFF_DIFFUSION_BIENTOT_TERMINEE,
    CST_STATUT_DIFF_DIFFUSION_DESACTIVEE,
    CST_STATUT_DIFF_DIFFUSION_EN_COURS,
    CST_STATUT_DIFF_DIFFUSION_PROGRAMMEE,
    CST_STATUT_DIFF_JAMAIS_DIFFUSE,
    CST_STATUT_DIFF_MISE_EN_LIGNE_EN_COURS,
    CST_STATUT_DIFF_MISE_EN_LIGNE_EN_ERREUR,
    CST_STATUT_DIFF_MISE_EN_LIGNE_REJETEE,
    CST_STATUT_DIFF_MISE_HORS_LIGNE_EN_COURS,
    CST_STATUT_DIFF_MISE_HORS_LIGNE_EN_ERREUR,
    CST_STATUT_DIFF_MISE_HORS_LIGNE_REJETEE,
    CST_STATUT_DIFF_NON_ELIGIBLE,
    Statut,
    StatutBuilder,
} from "../../../model/domain/StatutDomain";

const useStyles = makeStyles((_) => ({
    parent: {
        display: "grid",
        placeItems: "center",
    },
    green: {
        color: "#21b047",
    },
    green_beneficiaire: {
        color: "#21b047",
        backgroundColor: "pink",
        borderRadius: "50%",
    },
    red: {
        color: "#e60914",
    },
    blue: {
        color: "#0986e6",
    },
    blue_beneficiaire: {
        color: "#0986e6",
        backgroundColor: "pink",
        borderRadius: "50%",
    },
    orange: {
        color: "#dd510b",
    },
    orange_beneficiaire: {
        color: "#dd510b",
        backgroundColor: "pink",
        borderRadius: "50%",
    },
}));

export const StatutField = (props: { source: string; record?: any; label?: string; sortable?: boolean }) => {
    const { source, record = {} } = props;
    const classes = useStyles();
    const [statut, setStatut] = useState(new Statut());

    useEffect(() => {
        if (record) setStatut(StatutBuilder.get(record, source));
    }, [record, source]);

    const displayStatut = () => {
        switch (record[statut.statut]) {
            case CST_STATUT_DIFF_JAMAIS_DIFFUSE:
                return (
                    <Tooltip title={"Jamais diffusé"} arrow>
                        <VisibilityOffIcon />
                    </Tooltip>
                );
            case CST_STATUT_DIFF_MISE_EN_LIGNE_EN_COURS:
                return (
                    <Tooltip title={statut.info} arrow>
                        <UpdateIcon
                            className={statut.diffusionBeneficaire ? classes.blue_beneficiaire : classes.blue}
                        />
                    </Tooltip>
                );
            case CST_STATUT_DIFF_MISE_EN_LIGNE_REJETEE:
            case CST_STATUT_DIFF_MISE_HORS_LIGNE_REJETEE:
                return (
                    <Tooltip title={statut.info} arrow>
                        <CancelIcon className={classes.red} />
                    </Tooltip>
                );
            case CST_STATUT_DIFF_DIFFUSION_EN_COURS:
                return source === "coStatutLi9" ? (
                    <Tooltip title={statut.info} arrow>
                        {statut.diffusionBeneficaire ? (
                            <CheckCircleIcon className={classes.green_beneficiaire} />
                        ) : (
                            <IconButton
                                onClick={() =>
                                    window.open(
                                        process.env.REACT_APP_URL_ANNONCE_LI9?.replace(
                                            "[IDANNONCE]",
                                            record.idAnnonceWeb
                                        ),
                                        "_blank"
                                    )
                                }
                            >
                                <CheckCircleIcon className={classes.green} />
                            </IconButton>
                        )}
                    </Tooltip>
                ) : (
                    <Tooltip title={statut.info} arrow>
                        <CheckCircleIcon
                            className={statut.diffusionBeneficaire ? classes.green_beneficiaire : classes.green}
                        />
                    </Tooltip>
                );
            case CST_STATUT_DIFF_MISE_HORS_LIGNE_EN_COURS:
                return (
                    <Tooltip title={statut.info} arrow>
                        <UpdateIcon className={classes.blue} />
                    </Tooltip>
                );
            case CST_STATUT_DIFF_DIFFUSION_DESACTIVEE:
                return (
                    <Tooltip title={statut.info} arrow>
                        <BlockIcon className={classes.red} />
                    </Tooltip>
                );
            case CST_STATUT_DIFF_DIFFUSION_PROGRAMMEE:
                return (
                    <Tooltip title={statut.info} arrow>
                        <ScheduleIcon
                            className={statut.diffusionBeneficaire ? classes.blue_beneficiaire : classes.blue}
                        />
                    </Tooltip>
                );
            case CST_STATUT_DIFF_DIFFUSION_BIENTOT_TERMINEE:
                return source === "coStatutLi9" ? (
                    <Tooltip title={statut.info} arrow>
                        {statut.diffusionBeneficaire ? (
                            <HourglassEmptyIcon className={classes.orange_beneficiaire} />
                        ) : (
                            <IconButton
                                onClick={() =>
                                    window.open(
                                        process.env.REACT_APP_URL_ANNONCE_LI9?.replace(
                                            "[IDANNONCE]",
                                            record.idAnnonceWeb
                                        ),
                                        "_blank"
                                    )
                                }
                            >
                                <HourglassEmptyIcon className={classes.orange} />
                            </IconButton>
                        )}
                    </Tooltip>
                ) : (
                    <Tooltip title={statut.info} arrow>
                        <HourglassEmptyIcon
                            className={statut.diffusionBeneficaire ? classes.orange_beneficiaire : classes.orange}
                        />
                    </Tooltip>
                );
            case CST_STATUT_DIFF_NON_ELIGIBLE:
            case CST_STATUT_DIFF_MISE_EN_LIGNE_EN_ERREUR:
            case CST_STATUT_DIFF_MISE_HORS_LIGNE_EN_ERREUR:
                return (
                    <Tooltip title={statut.info} arrow>
                        <ErrorIcon className={classes.red} />
                    </Tooltip>
                );
            default:
                return null;
        }
    };

    return <div className={classes.parent}>{displayStatut()}</div>;
};
