import ClientVO from "./ClientVO";

class UserVO {
    readonly id: Number;
    readonly code: String;
    readonly login: String;
    readonly nom: String;
    readonly prenom: String;
    readonly typeUser: String;
    readonly token: String;
    readonly client: ClientVO;

    constructor(auth: any) {
        this.id = auth.id || 0;
        this.code = auth.code;
        this.login = auth.login;
        this.nom = auth.nom;
        this.prenom = auth.prenom;
        this.typeUser = auth.typeUser;
        this.token = auth.token;
        this.client = auth.client;
    }
}

export default UserVO;
