class PrestationSupportVO {
    private id: Number;
    private coSupport: String | null;
    private liSupport: String;

    constructor(datum: Record<any, any> | undefined) {
        if (datum === undefined) {
            this.id = 0;
            this.coSupport = "";
            this.liSupport = "";
        } else {
            ({ id: this.id, coSupport: this.coSupport, liSupport: this.liSupport } = datum);
        }
    }
}

export default PrestationSupportVO;
