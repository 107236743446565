import { sessionUser } from "../../utils/SessionUser";

export const CST_STATUT_DIFF_JAMAIS_DIFFUSE = "JAMAIS_DIFFUSE";
export const CST_STATUT_DIFF_MISE_EN_LIGNE_EN_COURS = "MISE_EN_LIGNE_EN_COURS";
export const CST_STATUT_DIFF_MISE_EN_LIGNE_REJETEE = "MISE_EN_LIGNE_REJETEE";
export const CST_STATUT_DIFF_MISE_EN_LIGNE_EN_ERREUR = "MISE_EN_LIGNE_EN_ERREUR";
export const CST_STATUT_DIFF_MISE_HORS_LIGNE_EN_ERREUR = "MISE_HORS_LIGNE_EN_ERREUR";
export const CST_STATUT_DIFF_DIFFUSION_EN_COURS = "DIFFUSION_EN_COURS";
export const CST_STATUT_DIFF_MISE_HORS_LIGNE_EN_COURS = "MISE_HORS_LIGNE_EN_COURS";
export const CST_STATUT_DIFF_MISE_HORS_LIGNE_REJETEE = "MISE_HORS_LIGNE_REJETEE";
export const CST_STATUT_DIFF_DIFFUSION_DESACTIVEE = "DIFFUSION_DESACTIVEE";
export const CST_STATUT_DIFF_DIFFUSION_PROGRAMMEE = "DIFFUSION_PROGRAMMEE";
export const CST_STATUT_DIFF_DIFFUSION_BIENTOT_TERMINEE = "DIFFUSION_BIENTOT_TERMINEE";
export const CST_STATUT_DIFF_NON_ELIGIBLE = "NON_ELIGIBLE";

const DIFF_EN_LIGNE = [
    CST_STATUT_DIFF_DIFFUSION_BIENTOT_TERMINEE,
    CST_STATUT_DIFF_MISE_EN_LIGNE_EN_COURS,
    CST_STATUT_DIFF_DIFFUSION_EN_COURS,
    CST_STATUT_DIFF_DIFFUSION_PROGRAMMEE,
];

const DIFF_HORS_LIGNE = [
    CST_STATUT_DIFF_DIFFUSION_DESACTIVEE,
    CST_STATUT_DIFF_MISE_HORS_LIGNE_EN_COURS,
    CST_STATUT_DIFF_JAMAIS_DIFFUSE,
];

export class Statut {
    public diffusionBeneficaire: boolean = false;
    public statut: string = "";
    public info: string = "";

    isDiffusionBeneficiaire(record: any, coStatut: string) {
        let coStatutBenef = coStatut + "Benef";

        if (sessionUser.isCompteService() && record.idClientBenef && DIFF_EN_LIGNE.includes(record[coStatutBenef])) {
            this.diffusionBeneficaire = true;
        }
    }

    setStatut(record: any, coStatut: string) {
        let coStatutBenef = coStatut + "Benef";

        if (this.diffusionBeneficaire) {
            this.statut = coStatutBenef;
        } else if (
            sessionUser.isBeneficiaire() &&
            record[coStatutBenef] &&
            !DIFF_HORS_LIGNE.includes(record[coStatutBenef])
        ) {
            this.statut = coStatutBenef;
        } else {
            this.statut = coStatut;
        }
    }

    setInfo(record: any, coStatut: string) {
        if (this.diffusionBeneficaire) {
            this.info = "La diffusion est effectuée par un compte bénéficiaire";
        } else if (coStatut === "coStatutLi" && record[this.statut] !== CST_STATUT_DIFF_JAMAIS_DIFFUSE) {
            this.info = record.infoLi;
        } else if (coStatut === "coStatutLi9" && record[this.statut] !== CST_STATUT_DIFF_JAMAIS_DIFFUSE) {
            this.info = record.infoLiNeuf;
        }
    }
}

export class StatutBuilder {
    static get(record: any, coStatut: string): Statut {
        if (record == null) throw new Error("Le programme est invalide (null)");
        let statut = new Statut();
        statut.isDiffusionBeneficiaire(record, coStatut);
        statut.setStatut(record, coStatut);
        statut.setInfo(record, coStatut);
        return statut;
    }
}
