const EtageChoices = [
    {
        id: "RDC",
        name: "lipro.edit_lots_label.caract_complementaires.etage.rdc",
    },
    {
        id: "1",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage1",
    },
    {
        id: "2",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage2",
    },
    {
        id: "3",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage3",
    },
    {
        id: "4",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage4",
    },
    {
        id: "5",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage5",
    },
    {
        id: "6",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage6",
    },
    {
        id: "7",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage7",
    },
    {
        id: "8",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage8",
    },
    {
        id: "9",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage9",
    },
    {
        id: "10",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage10",
    },
    {
        id: "11",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage11",
    },
    {
        id: "12",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage12",
    },
    {
        id: "13",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage13",
    },
    {
        id: "14",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage14",
    },
    {
        id: "15",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage15",
    },
    {
        id: "16",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage16",
    },
    {
        id: "17",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage17",
    },
    {
        id: "18",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage18",
    },
    {
        id: "19",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage19",
    },
    {
        id: "20",
        name: "lipro.edit_lots_label.caract_complementaires.etage.etage20",
    },
    {
        id: "REZ DE JARDIN",
        name: "lipro.edit_lots_label.caract_complementaires.etage.rez_jardin",
    },
    {
        id: "PLAIN PIED",
        name: "lipro.edit_lots_label.caract_complementaires.etage.plain_pied",
    },
];

export default EtageChoices;
