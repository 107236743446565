import * as React from "react";
import { useContext, useState } from "react";
import { TextField as MUITextField } from "@material-ui/core";
import { useNotify, useTranslate } from "react-admin";
import { UserContext } from "../../utils/SessionUser";
import { useHistory } from "react-router-dom";
import { ClientProvider } from "../../providers/clientProvider";

export const ClientTextField = (props: { label: string }) => {
    const { label } = props;
    const [code, setCode] = useState("");
    const notify = useNotify();
    const translate = useTranslate();
    const history = useHistory();
    const sessionUserContext = useContext(UserContext);
    const clientProvider = new ClientProvider(sessionUserContext);

    const handleSetClient = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCode(event.target.value);
    };

    const errorFallback = (status: number) => {
        if (status === 401 || status === 403) {
            notify("lipro.notification.non_autorise", "error", translate);
        }
        if (status === 500) {
            notify("lipro.notification.client_inconnu", "warning", translate);
        }
    };

    //coClient ou coRcu
    const validateCode = (value: string) => {
        clientProvider
            .fetchClient(value, errorFallback)
            .then((client) => [sessionUserContext.setClient(client), window.location.reload()])
            .catch((error) => error.message);
    };

    return (
        <form autoComplete="off">
            <MUITextField
                label={label}
                variant="outlined"
                color="secondary"
                value={code}
                onChange={(event) => handleSetClient(event)}
                onKeyPress={(event) =>
                    event.key === "Enter" ? [event.preventDefault(), validateCode(code), history.push("/biens")] : null
                }
            />
        </form>
    );
};
