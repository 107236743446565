import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";

export const CancelButton = (props: { label: string; onClick: () => void }) => (
    <Button
        variant="contained"
        style={{ backgroundColor: "red", color: "white", marginLeft: "10px" }}
        onClick={props.onClick}
    >
        <CloseIcon fontSize="small" />
        &nbsp;
        {props.label}
    </Button>
);
