import ProgrammeVO from "../../../model/ProgrammeVO";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { useContext } from "react";
import { useGetOne, useTranslate } from "react-admin";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { UserContext } from "../../../utils/SessionUser";

const BandeauProgramme = (props: { typeBien?: string; children?: any }) => {
    const translate = useTranslate();
    const sessionUserContext = useContext(UserContext);

    const { typeBien, children } = props;
    const idProgramme = sessionUserContext.getIdProgramme();

    const { data } = useGetOne("biens", idProgramme);
    if (!data) {
        return null;
    }

    const currentProgramme = new ProgrammeVO(data);
    if (!currentProgramme || !currentProgramme.isValid()) {
        return null;
    }
    const maj = new Date(currentProgramme.dtModif).toLocaleDateString();

    const displayNumber = (nb: number) => {
        return nb > 0 ? nb : nb === -1 ? "NC" : "";
    };

    return (
        <Grid container direction="row" justify="space-between" alignItems="flex-end">
            <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                    {typeBien
                        ? translate("lipro.grid_lots_label.bandeau_programme.title", {
                              nom_programme: currentProgramme.liNom,
                          }) +
                          " | " +
                          typeBien
                        : translate("lipro.grid_lots_label.bandeau_programme.title", {
                              nom_programme: currentProgramme.liNom,
                          }) +
                          " | " +
                          translate("lipro.grid_lots_label.title")}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                {children}
            </Grid>
            <Grid item>
                <Typography variant="body2">
                    {translate("lipro.grid_lots_label.bandeau_programme.reference") +
                        " : " +
                        currentProgramme.rfRefClient}
                </Typography>
                <Typography variant="body2">
                    {translate("lipro.grid_lots_label.bandeau_programme.secteur") +
                        " : " +
                        currentProgramme.liLocalisation}
                </Typography>
                <Typography variant="body2">
                    {translate("lipro.grid_lots_label.bandeau_programme.cp") + " : " + currentProgramme.liCodepostal}
                </Typography>
                <Typography variant="body2">
                    {translate("lipro.grid_lots_label.bandeau_programme.maj") + " : " + maj}
                </Typography>
            </Grid>
            <Grid item>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">{translate("lipro.grid_label.studio")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.t1")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.t2")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.t3")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.t4")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.t5")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.m3")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.m4")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.m5")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.m6")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.parking")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.box")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.commerce")}</TableCell>
                                <TableCell align="right">{translate("lipro.grid_label.total")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="right">{displayNumber(currentProgramme.nbStudio)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_T1)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_T2)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_T3)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_T4)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_T5Plus)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_maison_3)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_maison_4)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_maison_5)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_maison_6plus)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_parking)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_box)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_commerce)}</TableCell>
                                <TableCell align="right">{displayNumber(currentProgramme.nb_lgt)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default BandeauProgramme;
