import React, { useContext, useState } from "react";
import { useStyles } from "../formStyles";
import { FileInput, Identifier, TextInput, useNotify, useRefresh, useTranslate } from "react-admin";
import { UserContext } from "../../../utils/SessionUser";
import { EditProvider } from "../../../providers/editProvider";
import { CircularProgress, Grid, IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import arrayBufferToBase64 from "../../../utils/arrayBufferToBase64";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const errorFallback = (status: number) => {
    console.error("Erreur lors du chargement de la plaquette : " + status);
};

function deletePlaquetteCommerciale(
    idProgramme: Identifier,
    editProvider: EditProvider,
    onSuccess: (message: string) => void
) {
    editProvider
        .deletePlaquetteCommerciale(idProgramme, errorFallback)
        .then(() => onSuccess("lipro.notification.plaquette_delete"));
}

function uploadPlaquetteCommerciale(
    idProgramme: Identifier,
    plaquetteCommercialeParameter: { value: string },
    editProvider: EditProvider,
    onSuccess: (message: string) => void
) {
    editProvider
        .uploadPlaquetteCommerciale(idProgramme, plaquetteCommercialeParameter, errorFallback)
        .then(() => onSuccess("lipro.notification.plaquette_upload"));
}

const useFileInputStyles = makeStyles(
    (theme) => ({
        dropZone: {
            background: theme.palette.background.default,
            cursor: "pointer",
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.getContrastText(theme.palette.background.default),
            border: "1px dotted",
        },
        preview: {
            visibility: "visible",
            display: "grid",
            placeItems: "center",
        },
        removeButton: {
            visibility: "hidden",
        },
        root: { width: "100%" },
    }),
    { name: "RaFileInput" }
);

const CommunicationClientTab = (props: any) => {
    const translate = useTranslate();
    const classes = useStyles();
    const fileInputClasses = useFileInputStyles();
    const sessionUserContext = useContext(UserContext);
    const { record } = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const editProvider = new EditProvider(sessionUserContext);
    const [loading, setLoading] = useState(false);

    const onSuccess = (message: string) => {
        refresh();
        notify(message, "info", translate);
    };

    const fileInputHandler = (file: File | null) => {
        if (record === undefined) {
            notify("lipro.notification.erreur_null", "error", translate);
            return;
        }
        let idProgramme: Identifier = record.id;
        if (file === null) {
            deletePlaquetteCommerciale(idProgramme, editProvider, onSuccess);
        } else if (file instanceof File) {
            file.arrayBuffer().then((value: ArrayBuffer) => {
                const plaquetteCommercialeParameter = {
                    value: arrayBufferToBase64(value),
                };
                uploadPlaquetteCommerciale(idProgramme, plaquetteCommercialeParameter, editProvider, onSuccess);
            });
        }
    };

    function formatPDF(value: any) {
        if (!value || typeof value === "string") {
            return null;
        }
        return value;
    }

    const PlaquetteCommercialePresente = () => (
        <Grid container direction="row" justify="flex-start" alignItems="center">
            {!loading && (
                <IconButton
                    onClick={() => [setLoading(true), deletePlaquetteCommerciale(record?.id, editProvider, onSuccess)]}
                    style={{ color: "red" }}
                >
                    <DeleteIcon />
                </IconButton>
            )}
            {loading && <CircularProgress size={15} style={{ marginRight: "5px" }} />}
            <a href={record?.plaquetteCommerciale} target="_blank" rel="noreferrer">
                {translate("lipro.edit_label.com_client.plaquette_commerciale.display")}
            </a>
        </Grid>
    );

    const PlaquetteCommercialeLoading = () => (
        <FileInput
            source="plaquetteCommerciale"
            format={formatPDF}
            label={translate("lipro.edit_label.com_client.plaquette_commerciale.label")}
            accept="application/pdf"
            onChange={fileInputHandler}
            classes={fileInputClasses}
        >
            <div>
                <CircularProgress />
            </div>
        </FileInput>
    );

    const plaquetteCommercialeUpload = record?.plaquetteCommerciale ? (
        <PlaquetteCommercialePresente />
    ) : (
        <PlaquetteCommercialeLoading />
    );
    const editMode = Object.keys(record).length !== 0;
    return (
        <>
            <TextInput
                className={classes.largeField}
                label={translate("lipro.edit_label.com_client.url_programme")}
                source="urlProgramme"
            />
            <br />
            {editMode && plaquetteCommercialeUpload}
            {!editMode && (
                <p>
                    <InfoOutlinedIcon
                        color={"secondary"}
                        fontSize={"inherit"}
                        style={{ marginBottom: -2, marginRight: 1 }}
                    />
                    {translate("lipro.edit_label.com_client.plaquette_commerciale.enregistrer_avant_upload")}
                </p>
            )}
        </>
    );
};

export default CommunicationClientTab;
