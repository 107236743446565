import { /*React,*/ useContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { UserContext } from "../../utils/SessionUser";
import { Error, Loading /*, useAuthState*/ } from "react-admin";
import { SSOProvider } from "../../providers/ssoProvider";

interface ParamTypes {
    idSso: string;
}

const LoginFromSSO = () => {
    const sessionUserContext = useContext(UserContext);
    let { idSso } = useParams<ParamTypes>();
    const [loading, setLoading] = useState(true);

    const errorFallback = (status: number) => {
        console.error("Erreur lors de la connexion : " + status);
    };

    useEffect(() => {
        // purge session
        sessionUserContext.removeSession();
        // load new contexte
        const ssoProvider = new SSOProvider(sessionUserContext);
        ssoProvider
            .fetchClientByIdSSO(idSso, errorFallback)
            .then((user) => {
                return [sessionUserContext.setUser(user), sessionUserContext.setClient(user?.client)];
            })
            .finally(() => {
                setLoading(false);
            });
    }, [sessionUserContext, idSso]);

    if (loading) {
        return <Loading />;
    }

    const user = sessionUserContext.getUser();
    const client = sessionUserContext.getClient();
    if (user && client) {
        return <Redirect to={"/"} />;
    }

    return <Error error={"lipro.notification.sso_invalide"} />;
};

export default LoginFromSSO;
