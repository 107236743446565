import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((_) => ({
    parent: {
        display: "grid",
        placeItems: "center",
    },
    green: {
        color: "#21b047",
    },
    red: {
        color: "#e60914",
    },
    blue: {
        color: "#0986e6",
    },
    orange: {
        color: "#ff7f40",
    },
    grey: {
        color: "#b8b8b8",
    },
}));

export const VideoField = (props: { source: string; record?: any; label?: string; sortable?: boolean }) => {
    const { source, record = {} } = props;
    const classes = useStyles();

    const CST_STATUT_NON_ELIGIBLE = "NON_ELIGIBLE";
    const CST_STATUT_ERREUR = "ERREUR";
    const CST_STATUT_EN_CONSTRUCTION = "EN_CONSTRUCTION";
    const CST_STATUT_GENEREE = "GENEREE";
    const CST_STATUT_SUSPENDUE = "SUSPENDUE";

    const videoTooltip = (statut: string) => {
        return (
            <React.Fragment>
                <Typography variant={"inherit"}>{statut}</Typography>
                {record.videoErrors.map((videoError: string, id: number) => (
                    <Typography key={id} variant={"inherit"}>
                        <br /> {videoError}
                    </Typography>
                ))}
            </React.Fragment>
        );
    };

    const displayVideo = () => {
        switch (record[source]) {
            case CST_STATUT_GENEREE:
                return (
                    <Tooltip title={videoTooltip("Vidéo générée / diffusée")} arrow>
                        <IconButton onClick={() => window.open(record?.videoUrl, "_blank")}>
                            <PlayCircleFilledWhiteIcon className={classes.green} />
                        </IconButton>
                    </Tooltip>
                );
            case CST_STATUT_EN_CONSTRUCTION:
                return (
                    <Tooltip title={videoTooltip("Vidéo en construction")} arrow>
                        <PlayCircleFilledWhiteIcon className={classes.blue} />
                    </Tooltip>
                );
            case CST_STATUT_NON_ELIGIBLE:
                return (
                    <Tooltip title={videoTooltip("Vidéo non éligible")} arrow>
                        <PlayCircleFilledWhiteIcon className={classes.orange} />
                    </Tooltip>
                );
            case CST_STATUT_SUSPENDUE:
                return (
                    <Tooltip title={videoTooltip("Vidéo hors ligne")} arrow>
                        <PlayCircleFilledWhiteIcon className={classes.grey} />
                    </Tooltip>
                );
            case CST_STATUT_ERREUR:
                return (
                    <Tooltip title={videoTooltip("Vidéo en erreur")} arrow>
                        <PlayCircleFilledWhiteIcon className={classes.red} />
                    </Tooltip>
                );
            default:
                return null;
        }
    };

    return <div className={classes.parent}>{displayVideo()}</div>;
};
