import * as React from "react";
import { SaveButton, Toolbar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
    toolbar: {
        display: "flex",
    },
});

const FormCustomToolbar = (props: any) => {
    const { info } = props;
    const classes = useStyles();

    return info ? (
        <Toolbar {...props} classes={classes}>
            <SaveButton />
            <Tooltip title={info} placement="right">
                <InfoIcon style={{ marginLeft: "10px" }} />
            </Tooltip>
        </Toolbar>
    ) : (
        <Toolbar {...props} classes={classes}>
            <SaveButton />
        </Toolbar>
    );
};

export default FormCustomToolbar;
