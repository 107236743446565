const TypeLogementChoices = [
    {
        id: "Collectif",
        name: "lipro.edit_label.infos_complementaires.type_logement.collectif",
    },
    {
        id: "Semi-collectif",
        name: "lipro.edit_label.infos_complementaires.type_logement.semi_collectif",
    },
    {
        id: "Individuel",
        name: "lipro.edit_label.infos_complementaires.type_logement.individuel",
    },
    {
        id: "Autres",
        name: "lipro.edit_label.infos_complementaires.type_logement.autres",
    },
];

export default TypeLogementChoices;
