import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { CreateButton, ListButton, TopToolbar, useTranslate } from "react-admin";
import BandeauProgramme from "./BandeauProgramme";
import * as React from "react";

const useStyle = makeStyles((theme) => ({
    toolbarLots: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    create: {
        marginRight: theme.spacing(2),
    },
}));

export const TopToolbarLots = () => {
    const history = useHistory();
    const classes = useStyle();
    const translate = useTranslate();

    return (
        <TopToolbar className={classes.toolbarLots}>
            <BandeauProgramme>
                <CreateButton variant="contained" className={classes.create} basePath={history.location.pathname} />
                <ListButton
                    label={translate("lipro.action.revenir_programmes")}
                    onClick={() => history.push("/biens")}
                />
            </BandeauProgramme>
        </TopToolbar>
    );
};
