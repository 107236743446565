const TypeLabelChoices = [
    {
        id: "BBC",
        name: "lipro.edit_label.infos_complementaires.type_label.bbc",
    },
    {
        id: "FCPI",
        name: "lipro.edit_label.infos_complementaires.type_label.fcpi",
    },
    {
        id: "FPI",
        name: "lipro.edit_label.infos_complementaires.type_label.fpi",
    },
    {
        id: "Habitat et Environnement",
        name: "lipro.edit_label.infos_complementaires.type_label.habitat_environnement",
    },
    {
        id: "HPS", // HPS et HPE dans code flex ?
        name: "lipro.edit_label.infos_complementaires.type_label.hpe",
    },
    {
        id: "HQE",
        name: "lipro.edit_label.infos_complementaires.type_label.hqe",
    },
    {
        id: "NF HABITAT HQE",
        name: "lipro.edit_label.infos_complementaires.type_label.nf_habitat_hqe",
    },
    {
        id: "NF HABITAT",
        name: "lipro.edit_label.infos_complementaires.type_label.nf_habitat",
    },
    {
        id: "NF Logement",
        name: "lipro.edit_label.infos_complementaires.type_label.nf_logement",
    },
    {
        id: "RT2012",
        name: "lipro.edit_label.infos_complementaires.type_label.rt2012",
    },
    {
        id: "THPE",
        name: "lipro.edit_label.infos_complementaires.type_label.thpe",
    },
    {
        id: "QUALITEL",
        name: "lipro.edit_label.infos_complementaires.type_label.qualitel",
    },
    {
        id: "Autres",
        name: "lipro.edit_label.infos_complementaires.type_label.autres",
    },
];

export default TypeLabelChoices;
