import * as React from "react";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import {
    Identifier,
    RecordMap,
    useGetList,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
    useUpdateMany,
} from "react-admin";
import { DiffuserButton } from "./widgets/DiffuserButton";
import { DiffuserProgrammesHandlers } from "./DiffuserProgrammesHandlers";
import { DiffuserItem } from "./widgets/DiffuserItem";
import { RestRoutes } from "../../constants/restRoutes";
import PrestationVO from "../../model/PrestationVO";
import { coType } from "../../constants/prestations";
import { MenuItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";

function getDiffusionPeriode(
    data: RecordMap<Record<any, any>>,
    id: string | number,
    periode: string,
    translate: (key: string, options?: any) => string
) {
    if (data[id].dtDebut && data[id].dtFin) {
        const dtDebut = new Date(data[id].dtDebut).toLocaleDateString();
        const dtFin = new Date(data[id].dtFin).toLocaleDateString();
        periode = translate("lipro.message.menu_diffusion_periode", {
            debut: dtDebut,
            fin: dtFin,
        });
    }
    return periode;
}

function getDiffuserItem(
    data: RecordMap<Record<any, any>>,
    id: string | number,
    translate: (key: string, options?: any) => string,
    open: boolean,
    loading: boolean,
    diffuserProgrammesHandlers: DiffuserProgrammesHandlers
) {
    let periode = "";
    let quantiteRestante: number;
    let descriptifPrestation: string; // TODO : vérifier l'affichage si pas de descriptif => Log warning ?

    if (data == null) {
        return <></>;
    }
    periode = getDiffusionPeriode(data, id, periode, translate);

    quantiteRestante = data[id].qtQuantiteRestante;
    descriptifPrestation = data[id].liDescriptif;

    return DiffuserItem(
        id,
        descriptifPrestation,
        quantiteRestante,
        translate,
        periode,
        open,
        loading,
        diffuserProgrammesHandlers
    );
}

const DiffuserProgrammes = (props: { selectedIds: (string | number)[] }) => {
    const translate = useTranslate();

    const selectedIds = props.selectedIds;

    const { data, ids } = useGetList(
        RestRoutes.prestations,
        { page: 1, perPage: 10 },
        { field: "id", order: "ASC" },
        {}
    );

    const [prestationSelected, setPrestationSelected] = useState<string | number>("");

    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const onUpdateSuccess = () => {
        refresh();
        notify("lipro.notification.biens_actives", "info", translate);
        unselectAll("biens");
    };
    const onUpdateFailure = () => notify("lipro.notification.erreur_diffusion", "warning", translate);
    const prestationSelectedVO = new PrestationVO(data?.[prestationSelected]);
    const [updateMany, { loading }] = useUpdateMany(
        "biens",
        selectedIds,
        {
            activation: {
                programmesId: selectedIds,
                prestationSelected: prestationSelectedVO,
            },
        },
        {
            onSuccess: onUpdateSuccess,
            onFailure: onUpdateFailure,
        }
    );

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);

    if (data == null) return <></>;

    const diffuserProgrammesHandlers = new DiffuserProgrammesHandlers(
        setAnchorEl,
        setOpen,
        setPrestationSelected,
        updateMany
    );

    const canDisplayPrestation = (_data: RecordMap<any>) => (_id: Identifier) => coType.includes(_data[_id].coType);

    return (
        <div style={{ marginTop: "-6px" }}>
            <DiffuserButton
                onClick={diffuserProgrammesHandlers.handleOpenMenu}
                label={translate("lipro.action.diffuser")}
            />
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={diffuserProgrammesHandlers.handleClose}
            >
                {ids && ids?.filter(canDisplayPrestation(data)).length > 0 ? (
                    ids?.filter(canDisplayPrestation(data)).map((id: string | number) => {
                        return getDiffuserItem(data, id, translate, open, loading, diffuserProgrammesHandlers);
                    })
                ) : (
                    <MenuItem disabled>
                        <ListItemText primary={translate("lipro.message.menu_aucune_prestation")} />
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default DiffuserProgrammes;
