class ContactVO {
    public readonly idContact: number;
    public readonly coOrigine: string;
    public readonly liObservation: string;
    public readonly liNom: string;
    public readonly noTel: string;
    public readonly noFax: string;
    public readonly liEmail: string;
    public readonly liSite: string;

    constructor(datum?: Record<any, any>) {
        this.idContact = (datum && datum.idContact) || 0;
        this.coOrigine = (datum && datum.coOrigine) || "";
        this.liObservation = (datum && datum.liObservation) || "Automatique";
        this.liNom = (datum && datum.liNom) || "";
        this.noTel = (datum && datum.noTel) || "";
        this.noFax = (datum && datum.noFax) || "";
        this.liEmail = (datum && datum.liEmail) || "";
        this.liSite = (datum && datum.liSite) || "";
    }
}

export default ContactVO;
