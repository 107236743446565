import { RestRoutes } from "../constants/restRoutes";
import restDataProvider from "@elbabass/ra-data-rest-client";
import { SessionUser } from "../utils/SessionUser";
import arrayBufferToBase64 from "../utils/arrayBufferToBase64";
import { fetchJson } from "./doFetch";

export const simpleRestClientBuilder = (sessionUser: SessionUser) => {
    const httpClient = (url: string, options: any = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: "application/json" });
        }
        const client = sessionUser.getClient();
        if (client.isValid()) {
            options.headers.set("IdClient", client.idClient);
        }
        const token = sessionUser.getToken() as string;
        if (token) {
            options.headers.set("Authorization", `${token}`);
        }
        const idProgramme = sessionUser.getIdProgramme();
        if (idProgramme) {
            options.headers.set("IdProgramme", idProgramme);
        }
        return fetchJson(url, options);
    };
    const dataProvider = restDataProvider(
        `${process.env.REACT_APP_API_ENDPOINT}`,
        {
            biens: "idBien",
            lots: "idBien",
        },
        {},
        httpClient
    );
    const transformMultimedias: (multimedias: any[]) => { images: any; logos: any } = (multimedias: any[]) => {
        let result = {
            images: [] as any,
            logos: [] as any,
        };
        for (let multimedia of multimedias) {
            if (multimedia.typeMultimedia) {
                const image = {
                    base64Content: multimedia.base64Content,
                    typeMultimedia: multimedia.typeMultimedia,
                    coSupport: "WEB",
                };
                result.images.push(image);
            } else {
                const logo = {
                    base64Content: multimedia.base64Content,
                    typeMultimedia: {
                        coTypeMult: "LOGO_COPRO",
                    },
                };
                result.logos.push(logo);
            }
        }
        return result;
    };

    const validRawFiles = (p: any) => p.rawFile && p.rawFile instanceof File;

    const extractValidFiles = (imagesGallerie: any) => {
        if (imagesGallerie) {
            return imagesGallerie.filter(validRawFiles);
        }
        return [];
    };

    const convertFileToBase64 = (file: { base64Content: any; rawFile: File }) =>
        file.rawFile.arrayBuffer().then((value) => (file.base64Content = arrayBufferToBase64(value)));

    return {
        ...dataProvider,
        create: (resource: any, params: any) => {
            const newImages = extractValidFiles(params.data.imagesGallerie);
            const newLogos = extractValidFiles(params.data.lstLogoCoPromoteur);
            if ((resource === "biens" && newImages.length < 0 && newLogos.length < 0) || resource === "lots") {
                return dataProvider.create(resource, params);
            }
            const newMultimedias = [...newImages, ...newLogos];

            return Promise.all(newMultimedias.map(convertFileToBase64))
                .then(() => transformMultimedias(newMultimedias))
                .then((multimedias) =>
                    dataProvider.create(resource, {
                        ...params,
                        data: {
                            ...params.data,
                            lstLogoCoPromoteur: [...multimedias.logos],
                            imagesGallerie: [...multimedias.images],
                        },
                    })
                );
        },
        update: (resource: any, params: any) => {
            const newImages = extractValidFiles(params.data.imagesGallerie);
            const newLogos = extractValidFiles(params.data.lstLogoCoPromoteur);

            if ((resource === "biens" && newImages.length < 0 && newLogos.length < 0) || resource === "lots") {
                return dataProvider.update(resource, params);
            }

            const formerImages = params.data.imagesGallerie.filter((p: any) => p.url);

            const formerLogos = params.data.lstLogoCoPromoteur.filter((p: any) => p.url);

            const newMultimedias = [...newImages, ...newLogos];

            return Promise.all(newMultimedias.map(convertFileToBase64))
                .then(() => transformMultimedias(newMultimedias))
                .then((multimedias) =>
                    dataProvider.update(resource, {
                        ...params,
                        data: {
                            ...params.data,
                            lstLogoCoPromoteur: [...formerLogos, ...multimedias.logos],
                            imagesGallerie: [...formerImages, ...multimedias.images],
                        },
                    })
                );
        },
        updateMany: (resource: any, params: any) => {
            if (Object.keys(params.data).includes("activation")) {
                return httpClient(`${process.env.REACT_APP_API_ENDPOINT}/${RestRoutes.activeProgrammesFromWeb}`, {
                    method: "POST",
                    body: JSON.stringify(params.data["activation"]),
                    headers: sessionUser.getAuthorizationHeader(),
                }).then(({ json }) => ({ data: json }));
            }
            if (Object.keys(params.data).includes("desactivation")) {
                return httpClient(`${process.env.REACT_APP_API_ENDPOINT}/${RestRoutes.desactiveProgrammesFromWeb}`, {
                    method: "POST",
                    body: JSON.stringify(params.data["desactivation"]),
                    headers: sessionUser.getAuthorizationHeader(),
                }).then(({ json }) => ({ data: json }));
            }
            if (Object.keys(params.data).includes("rapatriement")) {
                return httpClient(`${process.env.REACT_APP_API_ENDPOINT}/${RestRoutes.rapatrierProgramme}`, {
                    method: "POST",
                    body: JSON.stringify(params.data["rapatriement"]),
                    headers: sessionUser.getAuthorizationHeader(),
                }).then(({ json }) => ({ data: json }));
            } else {
                return httpClient(``, {
                    method: "POST",
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({ data: json }));
            }
        },
    };
};
