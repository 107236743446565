import { CreateButton, TopToolbar, useTranslate } from "react-admin";
import { RapatrierButton } from "../rapatrierProgrammes/widgets/RapatrierButton";
import * as React from "react";
import { useContext } from "react";
import { UserContext } from "../../utils/SessionUser";
import { useHistory } from "react-router-dom";
import { CancelButton } from "./CancelButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    rapatrierCompteService: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    toptoolbarList: {
        display: "flex",
        alignItems: "stretch",
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    create: {
        marginRight: theme.spacing(2),
    },
}));

export const BienTopToolbar = () => {
    const history = useHistory();
    const translate = useTranslate();
    const classes = useStyle();
    const sessionUserContext = useContext(UserContext);
    const idCompteService = sessionUserContext.getIdCompteService();

    return sessionUserContext.isBeneficiaire() ? (
        history.location.pathname === "/biens" ? (
            <TopToolbar className={classes.toptoolbarList}>
                <CreateButton variant="contained" className={classes.create} basePath={history.location.pathname} />
                <RapatrierButton
                    label={translate("lipro.action.rapatrier")}
                    onClick={() => history.push(`compte-service/${idCompteService}/biens`)}
                />
            </TopToolbar>
        ) : (
            <TopToolbar className={classes.rapatrierCompteService}>
                <Typography variant="h6">{translate("lipro.message.rapatrier_programme_titre")}</Typography>
                <CancelButton label={translate("lipro.action.annuler")} onClick={() => history.push("/biens")} />
            </TopToolbar>
        )
    ) : sessionUserContext.getClient().isValid() ? (
        <TopToolbar className={classes.toptoolbarList}>
            <CreateButton variant="contained" basePath={history.location.pathname} />
        </TopToolbar>
    ) : (
        <TopToolbar />
    );
};
