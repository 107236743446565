import React, { useContext } from "react";
import { Tooltip, Typography } from "@material-ui/core";
import { UserContext } from "../../../utils/SessionUser";

export const ProgrammeField = (props: { source: string; record?: any; label?: string; sortable?: boolean }) => {
    const { source, record = {} } = props;
    const sessionUserContext = useContext(UserContext);

    return sessionUserContext.isSalarie() ? (
        <Tooltip title={record?.id} arrow>
            <Typography component="span" variant="body2">
                {record[source]}
            </Typography>
        </Tooltip>
    ) : (
        <Typography component="span" variant="body2">
            {record[source]}
        </Typography>
    );
};
