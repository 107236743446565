import dpeHabitationChoices from "../constants/dpeHabitationChoices";
import dpeCommerceChoices from "../constants/dpeCommerceChoices";

export class Lot {
    needTypeDeBienBis = false;
    needNbPiecesComplement = true;
    needSurfaceHabitable = true;
    needSurfaceTerrain = false;
    needDefiscalisation = true;
    requireNbPieces = false;
    needDPE = true;
    dpeChoices = dpeHabitationChoices;
    needNatureChauffage = false;
    needTypeChauffage = true;
    needNbChambres = false;
    needNbSDB = false;
    needNbSalleEau = false;
    needEtage = false;
    needExposition = true;
    needBox = true;
    needGarage = true;
    needCave = false;
    needJardin = true;
    needCellier = true;
    needLoggia = true;
    needGrenier = false;
    needAscenceur = false;
    needBalcon = true;
    needClimatisation = true;
    needDuplex = false;
    needMeuble = true;
    needPiscine = false;
    needTerrasse = true;
}

class Appartement extends Lot {
    needTypeDeBienBis = true;
    requireNbPieces = true;
    needNatureChauffage = true;
    needNbChambres = true;
    needNbSDB = true;
    needNbSalleEau = true;
    needEtage = true;
    needCave = true;
    needAscenceur = true;
    needDuplex = true;
    needPiscine = true;
}

class Parking extends Lot {
    needNbPiecesComplement = false;
    needSurfaceHabitable = false;
    needDefiscalisation = false;
    needDPE = false;
    needTypeChauffage = false;
    needExposition = false;
    needBox = false;
    needGarage = false;
    needJardin = false;
    needCellier = false;
    needLoggia = false;
    needBalcon = false;
    needClimatisation = false;
    needMeuble = false;
    needTerrasse = false;
}

class Studio extends Lot {
    needNbPiecesComplement = false;
    needNatureChauffage = true;
    needNbChambres = true;
    needNbSDB = true;
    needNbSalleEau = true;
    needEtage = true;
    needCave = true;
    needAscenceur = true;
    needDuplex = true;
    needPiscine = true;
}

class Maison extends Lot {
    needSurfaceTerrain = true;
    requireNbPieces = true;
    needNbChambres = true;
    needNbSDB = true;
    needNbSalleEau = true;
    needPiscine = true;
}

class Commerce extends Lot {
    needNatureChauffage = true;
    needEtage = true;
    needGrenier = true;
    needAscenceur = true;
    needDuplex = true;
    dpeChoices = dpeCommerceChoices;
}

export class LotBuilder {
    static get(record: any): Lot {
        if (record == null) throw new Error("Le lot est invalide (null)");
        switch (record.liTypeBien) {
            case "Studio":
                return new Studio();
            case "Box":
            case "Parking":
                return new Parking();
            case "Appartement":
                return new Appartement();
            case "Maison":
                return new Maison();
            case "Commerce":
                return new Commerce();
            default:
                throw new Error("Le type de lot est invalide");
        }
    }
}
