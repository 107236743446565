import { ListButton, TopToolbar } from "react-admin";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
    formTopToolbar: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
}));

const FormActions = (props: { basePath?: string; label: string; title: string }) => {
    const classes = useStyle();

    return (
        <TopToolbar className={classes.formTopToolbar}>
            <Typography variant="h6">{props.title}</Typography>
            <ListButton basePath={props.basePath} label={props.label} />
        </TopToolbar>
    );
};

export default FormActions;
