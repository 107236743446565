import { useStyles } from "../biens/formStyles";
import { useTranslate } from "react-admin";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

const CustomRemoveButton = (props: any) => {
    const classes = useStyles(props);
    const translate = useTranslate();
    return (
        <Button color="primary" size="small" {...props}>
            <DeleteIcon className={classes.leftIcon} />
            {translate("ra.action.remove")}
        </Button>
    );
};

export default CustomRemoveButton;
