import React, { useContext, useEffect, useState } from "react";
import { Edit, Error, FormTab, TabbedForm, TabbedFormTabs, useEditController, useTranslate } from "react-admin";
import FormCustomToolbar from "../biens/widgets/FormCustomToolbar";
import CaractLotTab from "./formTabs/CaractLotTab";
import DescriptionLotTab from "./formTabs/DescriptionLotTab";
import CaractComplementairesLotTab from "./formTabs/CaractComplementairesLotTab";
import LotFormActions from "./widgets/LotFormActions";
import { Lot, LotBuilder } from "../../model/domain/LotDomain";
import URLLotTab from "./formTabs/URLLotTab";
import { UserContext } from "../../utils/SessionUser";

const LotEdit = (props: any) => {
    const translate = useTranslate();
    const { record } = useEditController(props);
    const sessionUserContext = useContext(UserContext);
    const client = sessionUserContext.getClient();

    const [lot, setLot] = useState<Lot>(new Lot());
    useEffect(() => {
        if (record) setLot(LotBuilder.get(record));
    }, [record]);

    // noinspection RequiredAttributes
    return client.isValid() ? (
        <Edit
            mutationMode="pessimistic"
            actions={<LotFormActions label={translate("lipro.action.revenir_lots")} typeBien={record?.liTypeBien} />}
            {...props}
        >
            <TabbedForm
                tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
                toolbar={<FormCustomToolbar />}
            >
                <FormTab label={translate("lipro.edit_lots_label.caract_lot.title")}>
                    <CaractLotTab lot={lot} />
                </FormTab>
                <FormTab label={translate("lipro.edit_lots_label.description.title")}>
                    <DescriptionLotTab />
                </FormTab>
                <FormTab label={translate("lipro.edit_lots_label.caract_complementaires.title")}>
                    <CaractComplementairesLotTab lot={lot} />
                </FormTab>
                <FormTab label={translate("lipro.edit_lots_label.url.title")}>
                    <URLLotTab />
                </FormTab>
            </TabbedForm>
        </Edit>
    ) : (
        <Error error={translate("lipro.notification.erreur_nouvel_onglet_form")} />
    );
};

export default LotEdit;
