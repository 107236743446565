const TypeBienChoices = [
    {
        id: "Appartement",
        name: "lipro.create_lots_label.criteres.type_bien.appart",
    },
    {
        id: "Maison",
        name: "lipro.create_lots_label.criteres.type_bien.maison",
    },
    {
        id: "Commerce",
        name: "lipro.create_lots_label.criteres.type_bien.commerce",
    },
    {
        id: "Parking",
        name: "lipro.create_lots_label.criteres.type_bien.parking",
    },
    {
        id: "Box",
        name: "lipro.create_lots_label.criteres.type_bien.box",
    },
    {
        id: "Studio",
        name: "lipro.create_lots_label.criteres.type_bien.studio",
    },
];

export default TypeBienChoices;
