import { useStyles } from "../formStyles";
import { SelectInput, useTranslate } from "react-admin";
import React, { useState } from "react";

const LIVRAISON_IDENTIFIANT_DEJA_LIVRE = "Déjà livré";

const getLivraisonForPeriod = (
    nbParAn: number,
    nbPeriodes: number,
    currentPeriode: number,
    translateNomPeriode: (numeroPeriode: number) => string,
    codePeriode: string
) => {
    const currentYear = new Date().getFullYear();

    let livraisonForPeriodeList = new Array<{ name: string; id: string }>();
    for (let periode = currentPeriode - 1; periode < nbPeriodes + currentPeriode; periode++) {
        let numeroPeriode = Math.floor((periode % nbParAn) + 1);
        livraisonForPeriodeList.push({
            id: `${Math.floor(currentYear + periode / nbParAn)}-${codePeriode}${numeroPeriode}`,
            name: `${Math.floor(currentYear + periode / nbParAn)}-` + translateNomPeriode(numeroPeriode),
        });
    }
    return livraisonForPeriodeList;
};

const PeriodeLivraisonInput = (props: { record: any }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const record = props.record;
    const create = Object.keys(record).length === 0;

    const [livraisonChoices, setLivraisonChoices] = useState([
        {
            id: LIVRAISON_IDENTIFIANT_DEJA_LIVRE,
            name: "lipro.edit_label.infos_principales.livraison.deja_livre",
        },
    ]);
    const [loadedChoices, setLoadedChoices] = useState(false);

    let createLivraisonChoices;

    function getLivraison(translate: (key: string, options?: any) => string, record?: Record<any, any>) {
        const date = new Date();

        const livraisonTrimestres = getLivraisonForPeriod(
            4,
            20,
            Math.floor((date.getMonth() + 3) / 3),
            (numeroPeriode: number) =>
                translate("lipro.edit_label.infos_principales.livraison.trimestre", {
                    smart_count: numeroPeriode,
                }),
            "T"
        );

        const livraisonSemestres = getLivraisonForPeriod(
            2,
            20 / 2,
            Math.floor((date.getMonth() + 6) / 6),
            (numeroSemestre: number) =>
                translate("lipro.edit_label.infos_principales.livraison.semestre", {
                    smart_count: numeroSemestre,
                }),
            "S"
        );

        let periodeLivraisonList = [...livraisonTrimestres, ...livraisonSemestres];

        periodeLivraisonList = periodeLivraisonList.sort((periode1, periode2) =>
            periode1.id.localeCompare(periode2.id)
        );

        let livraisonList = [];

        if (record) {
            livraisonList.push({
                id: "",
                name: "lipro.edit_label.infos_principales.livraison.non_communique",
            });
            // TODO : enregistrer "Déjà Livré" dans ce cas, mais sans warning
            const idDejaLivre = !livraisonTrimestres.some((choice) => choice.id === record.trimestreLivraison)
                ? record.trimestreLivraison
                : LIVRAISON_IDENTIFIANT_DEJA_LIVRE;
            livraisonList.push({
                id: idDejaLivre,
                name: "lipro.edit_label.infos_principales.livraison.deja_livre",
            });
        }

        return [...livraisonList, ...periodeLivraisonList];
    }

    if (create) {
        createLivraisonChoices = getLivraison(translate);
    } else {
        if (record && /* SCA PUBF-2109 record.trimestreLivraison && */ !loadedChoices) {
            setLivraisonChoices(getLivraison(translate, record));
            setLoadedChoices(true);
        }
    }

    return (
        <SelectInput
            className={classes.field}
            label={translate("lipro.edit_label.infos_principales.livraison.title")}
            source="trimestreLivraison"
            translateChoice
            choices={create ? createLivraisonChoices : livraisonChoices}
        />
    );
};

export default PeriodeLivraisonInput;
