import React from "react";
import { Lot } from "../../../model/domain/LotDomain";
import { BooleanInput, NumberInput, SelectInput, TextInput, useTranslate } from "react-admin";
import { useStyles } from "../../biens/formStyles";
import DPEField from "../../biens/widgets/DPEField";
import { Grid, Typography } from "@material-ui/core";
import NatureChauffageChoices from "../../../model/constants/natureChauffageChoices";
import TypeChauffageChoices from "../../../model/constants/typeChauffageChoices";
import ExpositionChoices from "../../../model/constants/ExpositionChoices";
import EtageChoices from "../../../model/constants/etageChoices";

const CaractComplementairesLotTab = (props: { lot: Lot }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const { lot } = props;

    return (
        <>
            {lot.needDPE && (
                <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                    <SelectInput
                        className={classes.field}
                        label={translate("lipro.edit_lots_label.caract_complementaires.dpe.title")}
                        source="dpe"
                        translateChoice
                        allowEmpty
                        optionText={<DPEField />}
                        choices={lot.dpeChoices}
                    />
                    <NumberInput
                        className={classes.field}
                        label={translate("lipro.edit_lots_label.caract_complementaires.valeur_energie")}
                        source="classeEnergie"
                    />
                </Grid>
            )}
            {lot.needNatureChauffage && (
                <SelectInput
                    className={classes.field}
                    label={translate("lipro.edit_lots_label.caract_complementaires.nature_chauffage.title")}
                    source="natureChauffage"
                    translateChoice
                    allowEmpty
                    choices={NatureChauffageChoices}
                />
            )}
            {lot.needTypeChauffage && (
                <>
                    <SelectInput
                        className={classes.field}
                        label={translate("lipro.edit_lots_label.caract_complementaires.type_chauffage.title")}
                        source="typeChauffage"
                        translateChoice
                        allowEmpty
                        choices={TypeChauffageChoices}
                    />
                    <br />
                </>
            )}
            {lot.needNbChambres && (
                <NumberInput
                    className={classes.field}
                    label={translate("lipro.edit_lots_label.caract_complementaires.nb_chambres")}
                    source="nbChambres"
                />
            )}
            {lot.needNbSDB && (
                <NumberInput
                    className={classes.field}
                    label={translate("lipro.edit_lots_label.caract_complementaires.nb_sdb")}
                    source="nbSdb"
                />
            )}
            {lot.needNbSalleEau && (
                <NumberInput
                    className={classes.field}
                    label={translate("lipro.edit_lots_label.caract_complementaires.nb_salle_eau")}
                    source="nbSalleEau"
                />
            )}
            {lot.needEtage && (
                <>
                    <SelectInput
                        className={classes.field}
                        label={translate("lipro.edit_lots_label.caract_complementaires.etage.title")}
                        source="etage"
                        translateChoice
                        allowEmpty
                        choices={EtageChoices}
                    />
                    <br />
                </>
            )}
            {lot.needExposition && (
                <>
                    <SelectInput
                        className={classes.field}
                        label={translate("lipro.edit_lots_label.caract_complementaires.exposition.title")}
                        source="exposition"
                        translateChoice
                        allowEmpty
                        choices={ExpositionChoices}
                    />
                    <br />
                </>
            )}
            <Typography variant="h6">{translate("lipro.edit_lots_label.caract_complementaires.annexes")}</Typography>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                {lot.needBox && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.box")}
                        source="box"
                    />
                )}
                {lot.needGarage && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.garage")}
                        source="garage"
                    />
                )}
                {lot.needCave && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.cave")}
                        source="cave"
                    />
                )}
                {lot.needJardin && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.jardin")}
                        source="jardin"
                    />
                )}
                {lot.needCellier && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.cellier")}
                        source="cellier"
                    />
                )}
                {lot.needLoggia && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.loggia")}
                        source="log"
                    />
                )}
                {lot.needGrenier && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.grenier")}
                        source="grenier"
                    />
                )}
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.grid_lots_label.complements.parking")}
                    source="parking"
                />
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.grid_lots_label.complements.parking_ext")}
                    source="parkingExt"
                />
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.grid_lots_label.complements.parking_int")}
                    source="parkingInt"
                />
            </Grid>
            <Typography variant="h6">{translate("lipro.edit_lots_label.caract_complementaires.autres")}</Typography>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.grid_lots_label.complements.acces_handicape")}
                    source="accesHandicape"
                />
                {lot.needAscenceur && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.ascenseur")}
                        source="ascenseur"
                    />
                )}
                {lot.needBalcon && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.balcon")}
                        source="balcon"
                    />
                )}
                {lot.needClimatisation && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.climatisation")}
                        source="climatisation"
                    />
                )}
                {lot.needDuplex && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.duplex")}
                        source="duplex"
                    />
                )}
                {lot.needMeuble && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.meuble")}
                        source="meuble"
                    />
                )}
                {lot.needPiscine && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.piscine")}
                        source="piscine"
                    />
                )}
                {lot.needTerrasse && (
                    <BooleanInput
                        className={classes.field}
                        label={translate("lipro.grid_lots_label.complements.terrasse")}
                        source="terrasse"
                    />
                )}
            </Grid>
            <TextInput
                multiline
                rows={5}
                className={classes.largeField}
                label={translate("lipro.edit_lots_label.caract_complementaires.desc_complementaire")}
                source="descriptifComplement"
            />
        </>
    );
};

export default CaractComplementairesLotTab;
