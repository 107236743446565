const TypeChauffageChoices = [
    {
        id: "Gaz",
        name: "lipro.edit_label.infos_complementaires.type_chauffage.gaz",
    },
    {
        id: "Electrique",
        name: "lipro.edit_label.infos_complementaires.type_chauffage.electrique",
    },
    {
        id: "Fuel",
        name: "lipro.edit_label.infos_complementaires.type_chauffage.fuel",
    },
    {
        id: "Bois",
        name: "lipro.edit_label.infos_complementaires.type_chauffage.bois",
    },
    {
        id: "Charbon",
        name: "lipro.edit_label.infos_complementaires.type_chauffage.charbon",
    },
    {
        id: "Solaire",
        name: "lipro.edit_label.infos_complementaires.type_chauffage.solaire",
    },
    {
        id: "Géothermie",
        name: "lipro.edit_label.infos_complementaires.type_chauffage.geothermie",
    },
    {
        id: "Autre",
        name: "lipro.edit_label.infos_complementaires.type_chauffage.autre",
    },
];

export default TypeChauffageChoices;
