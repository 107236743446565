import EnsembleVO from "../EnsembleVO";
import ClientVO from "../ClientVO";

export interface Utilisateur {
    coClient: String;
    liNom: String;
}

export abstract class ClientEnsembleUtilisateur implements Utilisateur {
    ensemble: EnsembleVO;
    coClient: String;
    liNom: String;

    protected constructor(client: ClientVO) {
        this.coClient = client.coClient;
        this.liNom = client.liNom;
        this.ensemble = client.ensemble;
    }
}
