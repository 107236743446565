import { useTranslate } from "react-admin";
import React from "react";

const DPEField = (props: { record?: any }) => {
    const translate = useTranslate();

    return props.record.icon ? (
        <div
            style={{
                position: "relative",
                textAlign: "center",
                color: props.record.id === "G" ? "white" : "black",
            }}
        >
            <img src={props.record.icon} alt={props.record.id} width="100%" />
            <span
                style={{
                    position: "absolute",
                    top: "40%",
                    left: "5%",
                    transform: "translate(-5%, -40%)",
                }}
            >
                {translate(props.record.name)}
            </span>
        </div>
    ) : (
        <span>{translate(props.record.name)}</span>
    );
};

export default DPEField;
