import React, { useEffect, useState } from "react";
import { ArrayInput, FormDataConsumer, ImageField, ImageInput, required, TextInput, useTranslate } from "react-admin";
import { useFormState } from "react-final-form";
import { useImageInputStyles } from "../formStyles";
import CustomAddButton from "../../widgets/CustomAddButton";
import CustomRemoveButton from "../../widgets/CustomRemoveButton";
import { Grid } from "@material-ui/core";
import ReorderedFormIterator from "../../raCustom/ReorderedFormIterator";

const LogosCoPromoteurTab = () => {
    const translate = useTranslate();
    const imageInputClasses = useImageInputStyles();
    const { values } = useFormState();
    const [nbLogos, setNbLogos] = useState(0);
    useEffect(() => {
        if (values && values.lstLogoCoPromoteur) {
            setNbLogos(values.lstLogoCoPromoteur.length);
        }
    }, [values]);

    return (
        <ArrayInput source="lstLogoCoPromoteur" label={translate("lipro.edit_label.logos_copromoteur.title")}>
            <ReorderedFormIterator
                disableReorderingOption
                disableAdd={nbLogos >= 4}
                addButton={<CustomAddButton />}
                removeButton={<CustomRemoveButton />}
            >
                <FormDataConsumer>
                    {({ getSource, scopedFormData }) => {
                        if (getSource) {
                            const regexIndex = /(\[{1})([0-3])(\]{1})/; // /(?<=\[)([0-3])(?=])/;
                            const source = getSource("");
                            let matchIndex = source.match(regexIndex);
                            if (matchIndex && matchIndex[0]) {
                                let index = +matchIndex[0] + 1;
                                if (scopedFormData?.url) {
                                    return (
                                        <Grid container direction="row" justify="flex-start" alignItems="center">
                                            <ImageField key={getSource("url")} source={"url"} record={scopedFormData} />
                                            <TextInput
                                                label={translate("lipro.edit_label.logos_copromoteur.code_promoteur")}
                                                source={"nomCoPromoteur" + index}
                                                validate={required()}
                                            />
                                        </Grid>
                                    );
                                } else {
                                    if (scopedFormData?.base64Content) {
                                        return (
                                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                                <ImageField
                                                    key={getSource("base64Content")}
                                                    source={"base64Content"}
                                                    record={scopedFormData}
                                                />
                                                <TextInput
                                                    label={translate(
                                                        "lipro.edit_label.logos_copromoteur.code_promoteur"
                                                    )}
                                                    source={"nomCoPromoteur" + index}
                                                    validate={required()}
                                                />
                                            </Grid>
                                        );
                                    } else {
                                        return (
                                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                                <Grid container item xs={4}>
                                                    <ImageInput
                                                        classes={imageInputClasses}
                                                        key={getSource("base64Content")}
                                                        source={getSource("")}
                                                        label=""
                                                        placeholder={
                                                            <p>
                                                                {translate(
                                                                    "lipro.edit_label.galerie_photos.dropzone_message"
                                                                )}
                                                                <br />
                                                                {translate(
                                                                    "lipro.edit_label.galerie_photos.dropzone_format"
                                                                )}
                                                            </p>
                                                        }
                                                        accept="image/jpg, image/jpeg"
                                                    >
                                                        <ImageField
                                                            key={getSource("base64Content")}
                                                            source="base64Content"
                                                        />
                                                    </ImageInput>
                                                </Grid>
                                                <TextInput
                                                    label={translate(
                                                        "lipro.edit_label.logos_copromoteur.code_promoteur"
                                                    )}
                                                    source={"nomCoPromoteur" + index}
                                                    validate={required()}
                                                />
                                            </Grid>
                                        );
                                    }
                                }
                            }
                        }
                    }}
                </FormDataConsumer>
            </ReorderedFormIterator>
        </ArrayInput>
    );
};
export default LogosCoPromoteurTab;
