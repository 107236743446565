import React from "react";
import { TextInput, useTranslate } from "react-admin";
import { useStyles } from "../../biens/formStyles";

const URLLotTab = () => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <>
            <TextInput
                className={classes.largeField}
                label={translate("lipro.edit_lots_label.url.url_standard")}
                source="urlStandard"
                type="url"
            />
            <br />
            <TextInput
                className={classes.largeField}
                label={translate("lipro.edit_lots_label.url.url_cible_immo")}
                source="urlCibleImmo"
                type="url"
            />
            <br />
            <TextInput
                className={classes.largeField}
                label={translate("lipro.edit_lots_label.url.url_social_immo")}
                source="urlSocialImmo"
                type="url"
            />
            <br />
            <TextInput
                className={classes.largeField}
                label={translate("lipro.edit_lots_label.url.url_push_immo")}
                source="urlPushImmo"
                type="url"
            />
        </>
    );
};

export default URLLotTab;
