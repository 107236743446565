import { SessionUser } from "../utils/SessionUser";
import { RestRoutes } from "../constants/restRoutes";
import { doFetch } from "./doFetch";

export class ClientProvider {
    private readonly sessionUser: SessionUser;

    constructor(sessionUser: SessionUser) {
        this.sessionUser = sessionUser;
    }

    async fetchClient(searchCode: string, errorFallback: (status: number) => void) {
        let parameter: string = searchCode;
        if (searchCode.length < 10 && searchCode.slice(0, 3) !== "RC-")
            parameter = Array(10 - searchCode.length + 1).join("0") + searchCode;
        const route = RestRoutes.getClientByCodeRcu;
        return await doFetch(this.sessionUser, route, errorFallback, parameter);
    }

    async fetchClientById(idClient: string, errorFallback: (status: number) => void) {
        return await doFetch(this.sessionUser, RestRoutes.getClientById, errorFallback, idClient);
    }
}
