import { SelectArrayInput } from "react-admin";
import { LocalActivity } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { EditProvider } from "../../../providers/editProvider";
import { UserContext } from "../../../utils/SessionUser";

const DefiscalisationInput = (props: { label: string; source: string; className?: string }) => {
    const { label, source, className } = props;

    const sessionUserContext = useContext(UserContext);
    const [defiscalisations, setDefiscalisations] = useState([]);

    const defiscListToString = (defisc: number[]) => {
        let defiscString = "";
        if (defisc.length > 0) {
            for (let i = 0; i < defisc.length - 1; i++) {
                defiscString += defisc[i] + ",";
            }
            defiscString += defisc[defisc.length - 1];
        }
        return defiscString;
    };

    const defiscStringToList = (defisc: string) => {
        return defisc ? defisc.split(",").map((id) => Number(id)) : [];
    };

    const errorFallback = (status: number) => {
        console.error("Erreur lors du chargement des défiscalisations : " + status);
    };

    useEffect(() => {
        const editProvider = new EditProvider(sessionUserContext);
        editProvider.fetchDefiscalisations(errorFallback).then((defiscs) =>
            setDefiscalisations(
                defiscs.map((defisc: any) => {
                    return {
                        id: defisc.idDefiscalisation,
                        name: defisc.libelle,
                    };
                })
            )
        );
    }, [sessionUserContext]);

    return defiscalisations.length > 0 ? (
        <SelectArrayInput
            className={className}
            label={label}
            source={source}
            format={defiscStringToList}
            parse={defiscListToString}
            translateChoice
            allowEmpty
            choices={defiscalisations}
        />
    ) : (
        <LocalActivity />
    );
};

export default DefiscalisationInput;
