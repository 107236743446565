import PrestationSupportVO from "./PrestationSupportVO";

class PrestationVO {
    private idPrestation: Number;
    private coType: String;
    private liDescriptif: String;
    private dtDebut: Date;
    private dtFin: Date;
    private qtQuantite: Number;
    private qtQuantiteRestante: Number;
    private prestationSupportList: PrestationSupportVO[];

    constructor(datum: Record<any, any> | undefined) {
        if (datum === undefined) {
            this.idPrestation = 0;
            this.coType = "";
            this.liDescriptif = "";
            this.dtDebut = new Date();
            this.dtFin = new Date();
            this.qtQuantite = 0;
            this.qtQuantiteRestante = 0;
            this.prestationSupportList = [];
        } else {
            ({
                /*id: this.idPrestation,*/
                idPrestation: this.idPrestation,
                coType: this.coType,
                liDescriptif: this.liDescriptif,
                dtDebut: this.dtDebut,
                dtFin: this.dtFin,
                qtQuantite: this.qtQuantite,
                qtQuantiteRestante: this.qtQuantiteRestante,
                prestationSupportList: this.prestationSupportList,
            } = datum);
        }
    }
}
export default PrestationVO;
