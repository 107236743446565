import BureauVenteTab from "./formTabs/BureauVenteTab";
import CommunicationClientTab from "./formTabs/CommunicationClientTab";
import ContactTab from "./formTabs/ContactTab";
import FormActions from "./widgets/FormActions";
import GaleriePhotoTab from "./formTabs/GaleriePhotoTab";
import InfosComplementairesTab from "./formTabs/InfosComplementairesTab";
import InfosPrincipalesTab from "./formTabs/InfosPrincipalesTab";
import LogosCoPromoteurTab from "./formTabs/LogosCoPromoteurTab";
import React, { useContext } from "react";
import { Edit, Error, FormTab, TabbedForm, TabbedFormTabs, useEditController, useTranslate } from "react-admin";
import FormCustomToolbar from "./widgets/FormCustomToolbar";
import { UserContext } from "../../utils/SessionUser";

export const BienEdit = (props: any) => {
    const translate = useTranslate();
    const { record } = useEditController(props);
    const sessionUserContext = useContext(UserContext);
    const client = sessionUserContext.getClient();

    // noinspection RequiredAttributes
    return client.isValid() ? (
        <Edit
            mutationMode="pessimistic"
            actions={
                <FormActions
                    label={translate("lipro.action.revenir_programmes")}
                    title={translate("lipro.edit_label.title", { nom: record?.liNom })}
                />
            }
            {...props}
        >
            <TabbedForm
                tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
                toolbar={<FormCustomToolbar />}
            >
                <FormTab label={translate("lipro.edit_label.infos_principales.title")}>
                    <InfosPrincipalesTab {...props} />
                </FormTab>
                <FormTab label={translate("lipro.edit_label.infos_complementaires.title")}>
                    <InfosComplementairesTab />
                </FormTab>
                <FormTab label={translate("lipro.edit_label.bureau_vente.title")}>
                    <BureauVenteTab {...props} />
                </FormTab>
                <FormTab label={translate("lipro.edit_label.galerie_photos.title")}>
                    <GaleriePhotoTab />
                </FormTab>
                <FormTab label={translate("lipro.edit_label.logos_copromoteur.title")}>
                    <LogosCoPromoteurTab />
                </FormTab>
                <FormTab label={translate("lipro.edit_label.contact.title")}>
                    <ContactTab />
                </FormTab>
                <FormTab label={translate("lipro.edit_label.com_client.title")}>
                    <CommunicationClientTab {...props} />
                </FormTab>
            </TabbedForm>
        </Edit>
    ) : (
        <Error error={translate("lipro.notification.erreur_nouvel_onglet_form")} />
    );
};
