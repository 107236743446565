import * as React from "react";
import { useState } from "react";
import { RapatrierButton } from "./widgets/RapatrierButton";
import { Confirm, useNotify, useTranslate, useUpdateMany } from "react-admin";
import { useHistory } from "react-router-dom";
import { RapatrierProgrammesHandlers } from "./RapatrierProgrammesHandlers";

const RapatrierProgrammes = (props: { selectedIds: (string | number)[] }) => {
    const selectedIds = props.selectedIds as string[];
    const translate = useTranslate();
    const history = useHistory();
    const notify = useNotify();

    const onUpdateSuccess = () => {
        history.push("/biens");
        notify("lipro.notification.programmes_rapatries", "info", translate);
    };

    const onUpdateFailure = () => notify("lipro.notification.erreur_rapatriement", "warning", translate);

    const [updateMany, { loading }] = useUpdateMany(
        "biens",
        selectedIds,
        {
            rapatriement: {
                programmesId: selectedIds,
            },
        },
        {
            onSuccess: onUpdateSuccess,
            onFailure: onUpdateFailure,
        }
    );

    const [open, setOpen] = useState(false);

    const rapatrierProgrammesHandlers = new RapatrierProgrammesHandlers(setOpen, updateMany);

    return (
        <div style={{ marginTop: "-6px" }}>
            <RapatrierButton
                onClick={() => rapatrierProgrammesHandlers.handleClick()}
                label={translate("lipro.action.rapatrier")}
            />
            <Confirm
                isOpen={open}
                loading={loading}
                title={translate("lipro.message.rapatrier_programme_titre")}
                content={translate("lipro.message.rapatrier_programme_contenu")}
                onConfirm={() => rapatrierProgrammesHandlers.handleConfirm()}
                onClose={() => rapatrierProgrammesHandlers.handleDialogClose()}
            />
        </div>
    );
};

export default RapatrierProgrammes;
