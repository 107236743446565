import { BooleanInput, NumberInput, required, useTranslate } from "react-admin";
import { Grid } from "@material-ui/core";
import React from "react";
import { useStyles } from "../../biens/formStyles";
import { useFormState } from "react-final-form";

const QteLotInput = (props: any) => {
    const translate = useTranslate();
    const classes = useStyles();
    const { record } = props;
    const { values } = useFormState();

    return record?.quantiteLots === -1 || Object.keys(record).length === 0 ? (
        <Grid container direction="row" justify="flex-start" alignItems="center">
            <NumberInput
                className={classes.field}
                disabled={values?.quantiteLots === -1}
                format={(value: number) => (value === -1 ? null : value)}
                label={translate("lipro.edit_lots_label.caract_lot.qte_lots.title")}
                source="quantiteLots"
            />
            <BooleanInput
                className={classes.field}
                label={translate("lipro.edit_lots_label.caract_lot.qte_lots.nc")}
                format={(value: number) => value === -1}
                parse={(value: boolean) => (value ? -1 : 0)}
                source="quantiteLots"
            />
        </Grid>
    ) : (
        <Grid container direction="row" justify="flex-start" alignItems="center">
            <NumberInput
                className={classes.field}
                label={translate("lipro.edit_lots_label.caract_lot.qte_lots.title")}
                source="quantiteLots"
                validate={required()}
            />
        </Grid>
    );
};

export default QteLotInput;
