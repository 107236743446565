import frenchMessages from "ra-language-french";

const messages = {
    fr: {
        ...frenchMessages,
        lipro: {
            notification: {
                biens_actives: "Biens activés",
                client_inconnu: "Le client que vous recherchez est inconnu",
                erreur_diffusion: "Erreur lors de la programmation de diffusion",
                desactivation: "Biens désactivés",
                erreur_desactivation: "Erreur lors de la désactivation de diffusion",
                non_autorise: "Accès refusé",
                programmes_rapatries: "Programmes rapatriés",
                erreur_rapatriement: "Erreur lors du rapatriement",
                sso_invalide: "SSO invalide",
                erreur_null: "Erreur de données",
                plaquette_upload: "Plaquette sauvegardée",
                plaquette_delete: "Plaquette supprimée",
                erreur_nouvel_onglet_form: "Impossible de charger le formulaire dans cet onglet",
                erreur_nouvel_onglet_lot: "Impossible de charger le tableau des lots dans cet onglet",
            },
            message: {
                diffuser_programme_contenu: "Souhaitez-vous programmer la mise en ligne de vos annonces sur LI.com ?",
                diffuser_programme_titre: "Diffusion",
                menu_diffusion_detail: "Vous pouvez encore diffuser %{contenu}",
                qt_annonce: "%{smart_count} annonce |||| %{smart_count} annonces",
                menu_diffusion_qt_annonce_0: "Vous ne pouvez plus diffuser d'annonce",
                menu_diffusion_periode: " du %{debut} au %{fin}",
                desactiver_programme_titre: "Désactivation",
                desactiver_programme_contenu: "Souhaitez-vous désactiver la mise en ligne de vos annonces ?",
                desactiver_tous_supports: "Désactiver sur tous les supports",
                desactiver_support: "Désactiver le support %{support}",
                rapatrier_programme_titre: "Rapatrier des programmes du compte de service",
                rapatrier_programme_contenu: "Souhaitez-vous rapatrier les programmes sélectionnés ?",
                menu_aucune_prestation: "Aucune prestation éligible",
            },
            action: {
                diffuser: "DIFFUSER",
                desactiver: "DESACTIVER",
                rapatrier: "RAPATRIER",
                annuler: "ANNULER",
                revenir_programmes: "REVENIR AUX PROGRAMMES",
                acceder_lots: "Accéder aux lots",
                revenir_lots: "REVENIR AUX LOTS",
                rechercher_programmes: "Rechercher dans les programmes",
            },
            grid_label: {
                title: "Mes programmes",
                reference: "Référence",
                programme: "Programme",
                cp: "CP",
                photos: "Photos",
                maj: "MAJ",
                studio: "S",
                t1: "T1",
                t2: "T2",
                t3: "T3",
                t4: "T4",
                t5: "T5+",
                m3: "M3",
                m4: "M4",
                m5: "M5",
                m6: "M6+",
                parking: "P",
                box: "B",
                commerce: "C",
                total: "Total",
                li: "Statut LI",
                li9: "Statut LI9",
                pl: "PL",
                videos: "Vidéo",
                origine: "Origine",
            },
            edit_label: {
                title: "Programme %{nom}",
                infos_principales: {
                    title: "Général",
                    nom_programme: "Nom du programme",
                    reference: "Référence",
                    phase_commerciale: {
                        title: "Phase commerciale",
                        avant_premiere: "Avant première",
                        lancement_commercial: "Lancement commercial",
                        demarrage_travaux: "Démarrage des travaux",
                        travaux_en_cours: "Travaux en cours",
                        derniere_opportunite: "Dernière opportunité",
                        fin_programme: "Fin de programme",
                    },
                    livraison: {
                        title: "Livraison",
                        non_communique: "Non communiqué",
                        deja_livre: "Déjà livré",
                        trimestre: "%{smart_count}er trimestre |||| %{smart_count}ème trimestre",
                        semestre: "%{smart_count}er semestre |||| %{smart_count}ème semestre",
                    },
                    pays: "Pays",
                    cp: "Code postal",
                    ville: "Ville",
                    adresse: "Adresse",
                    gps_latitude: "GPS (Latitude)",
                    gps_longitude: "GPS (Longitude)",
                    vente_copro: "Vente en copropriété",
                    nb_total: "Nb. total de logements",
                    descriptif: "Descriptif programme",
                },
                infos_complementaires: {
                    title: "Caractéristiques",
                    secteur: "Secteur",
                    proximite: "Proximité",
                    usage_logement: {
                        title: "Usage du logement",
                        residence_principale: "Résidence principale",
                        residence_services: "Résidence services",
                        residence_affaires: "Résidence d'affaires",
                        residence_secondaire: "Résidence secondaire / tourisme",
                        residence_etudiante: "Résidence étudiante",
                        residence_seniors: "Résidence séniors",
                    },
                    type_logement: {
                        title: "Type de logement",
                        collectif: "Collectif",
                        semi_collectif: "Semi-collectif",
                        individuel: "Individuel",
                        autres: "Autres",
                    },
                    defiscalisation: {
                        title: "Dispositif de défiscalisation",
                    },
                    type_chauffage: {
                        title: "Type de chauffage",
                        gaz: "Gaz",
                        electrique: "Electrique",
                        fuel: "Fuel",
                        bois: "Bois",
                        charbon: "Charbon",
                        solaire: "Solaire",
                        geothermie: "Géothermie",
                        autre: "Autre",
                    },
                    dpe: {
                        title: "Diagnostic performance énergétique",
                        non_communique: "Non communiqué",
                        non_soumis: "Non soumis",
                        vierge: "Vierge",
                        A: "≤ 50",
                        B: "51 à 90",
                        C: "91 à 150",
                        D: "151 à 230",
                        E: "231 à 330",
                        F: "331 à 450",
                        G: "> 450",
                    },
                    valeur_energie: "Valeur classe énergie",
                    type_label: {
                        title: "Type Label",
                        bbc: "BBC",
                        fcpi: "FCPI",
                        fpi: "FPI",
                        habitat_environnement: "Habitat et Environnement",
                        hpe: "HPE",
                        hqe: "HQE",
                        nf_habitat_hqe: "NF HABITAT HQE",
                        nf_habitat: "NF HABITAT",
                        nf_logement: "NF Logement",
                        rt2012: "RT2012",
                        thpe: "THPE",
                        qualitel: "QUALITEL",
                        autres: "Autres",
                    },
                    piscine: "Piscine",
                    ascenseur: "Ascenseur",
                    acces_handicape: "Accès handicapé",
                    interphone: "Interphone",
                    visiophone: "Visiophone",
                    digicode: "Digicode",
                    gardien: "Gardien",
                    jardin: "Jardin",
                    garage: "Garage",
                    box: "Box",
                    parking: "Parking",
                    texte_complementaire: "Texte complémentaire",
                },
                bureau_vente: {
                    title: "Bureau de vente",
                    cp: "Code postal",
                    ville: "Ville",
                    adresse: "Adresse",
                    gps_latitude: "GPS (Latitude)",
                    gps_longitude: "GPS (Longitude)",
                    telephone: "Téléphone",
                    email: "Email",
                    horaires_ouverture: "Horaires d'ouverture",
                    infos_complementaires: "Informations complémentaires",
                },
                galerie_photos: {
                    title: "Photothèque",
                    galerie_title: "Galerie photos",
                    url_visite_virtuelle: "URL visite virtuelle",
                    dropzone_message: "Déposez l'image à uploader, ou cliquez pour la sélectionner.",
                    dropzone_format: "Seuls les formats JPG/JPEG sont acceptés.",
                    tooltip_reorder:
                        "La galerie pourra être réorganisée après l'upload et l'enregistrement de vos nouvelles photos.",
                    type_multimedia: {
                        title: "Type de photo",
                        persp_princ: "Perspective principale",
                        illustration: "Illustration",
                        plan_masse: "Plan de masse",
                    },
                },
                logos_copromoteur: {
                    title: "Logos co-promoteurs",
                    code_promoteur: "Code promoteur",
                },
                contact: {
                    title: "Contact",
                    contacts: "Sélectionnez un de vos contacts",
                    nom: "Nom",
                    telephone: "Téléphone",
                    fax: "Fax",
                    email: "Email",
                    site_web: "Site Web",
                },
                com_client: {
                    title: "Communication client",
                    url_programme: "Url du programme (http://www)",
                    plaquette_commerciale: {
                        label: "Plaquette Commerciale",
                        delete: "Supprimer la plaquette commerciale",
                        display: "Afficher la plaquette commerciale",
                        enregistrer_avant_upload:
                            "Afin de pouvoir télécharger une plaquette au format PDF, veuillez enregistrer le programme en cours de création.",
                    },
                },
            },
            create_label: { title: "Nouveau programme" },
            grid_lots_label: {
                title: "Liste des lots",
                bandeau_programme: {
                    title: "Programme %{nom_programme}",
                    reference: "Référence",
                    secteur: "Secteur",
                    cp: "Code postal",
                    maj: "Mise à jour",
                },
                quantite: "Qté",
                type: "Type",
                pieces: "Pièces",
                surface: "Surface",
                prix: "Prix €",
                complements: {
                    title: "Compléments",
                    acces_handicape: "Accès handicapé",
                    ascenseur: "Ascenseur",
                    balcon: "Balcon",
                    climatisation: "Climatisation",
                    duplex: "Duplex",
                    meuble: "Meublé",
                    piscine: "Piscine",
                    terrasse: "Terrasse",
                    box: "Box",
                    cave: "Cave",
                    garage: "Garage",
                    parking: "Parking",
                    cellier: "Cellier",
                    parking_ext: "Parking extérieur",
                    parking_int: "Parking intérieur",
                    loggia: "Loggia",
                    jardin: "Jardin",
                    grenier: "Grenier",
                },
                photos: "Photos",
            },
            edit_lots_label: {
                caract_lot: {
                    title: "Général",
                    bien_bis: 'Type de bien "BIS"',
                    copro: "Copropriété",
                    qte_lots: {
                        title: "Quantité de lots",
                        nc: "Non communiquée",
                    },
                    n_lot: "N° lot présenté",
                    nb_pieces: "Nombre de pièces",
                    surface_habitable: "Surface habitable",
                    surface_terrain: "Surface terrain",
                    prix: "Prix",
                    conditions_prix: {
                        title: "Conditions prix",
                        nous_consulter: "Nous consulter",
                        renseignements_sur_ref: "Renseignements sur Réf.",
                    },
                    avert_prix:
                        'Pour que votre lot soit diffusé sur LICOM, vous devez obligatoirement renseigner un prix ou la mention "Nous consulter"',
                    defiscalisation: "Dispositif de défiscalisation",
                    secteur: "Secteur",
                    exclusivite: {
                        title: "Exclusivité",
                        exclu: "EXCLUSIVITE",
                        semi_exclu: "SEMI-EXCLUSIVITE",
                        co_exclu: "CO-EXCLUSIVITE",
                    },
                    url_visite_virtuelle: "Url visite virtuelle",
                },
                description: {
                    title: "Description",
                },
                caract_complementaires: {
                    title: "Caractéristiques",
                    dpe: {
                        title: "Diagnostic performance énergétique",
                        non_communique: "Non communiqué",
                        non_soumis: "Non soumis",
                        vierge: "Vierge",
                        commerce: {
                            A: "≤ 30",
                            B: "31 à 90",
                            C: "91 à 170",
                            D: "171 à 210",
                            E: "271 à 380",
                            F: "381 à 510",
                            G: "> 510",
                        },
                    },
                    valeur_energie: "Valeur classe énergie",
                    nature_chauffage: {
                        title: "Nature du chauffage",
                        individuel: "Individuel",
                        collectif: "Collectif",
                    },
                    type_chauffage: {
                        title: "Type de chauffage",
                    },
                    nb_chambres: "Nombre de chambres",
                    nb_sdb: "Nombre de salles de bain",
                    nb_salle_eau: "Nombre de salles d'eau",
                    etage: {
                        title: "Etage",
                        rdc: "RDC",
                        etage1: "1er",
                        etage2: "2e",
                        etage3: "3e",
                        etage4: "4e",
                        etage5: "5e",
                        etage6: "6e",
                        etage7: "7e",
                        etage8: "8e",
                        etage9: "9e",
                        etage10: "10e",
                        etage11: "11e",
                        etage12: "12e",
                        etage13: "13e",
                        etage14: "14e",
                        etage15: "15e",
                        etage16: "16e",
                        etage17: "17e",
                        etage18: "18e",
                        etage19: "19e",
                        etage20: "20e",
                        rez_jardin: "Rez de jardin",
                        plain_pied: "Plain pied",
                    },
                    exposition: {
                        title: "Exposition",
                        nord: "Nord",
                        sud: "Sud",
                        est: "Est",
                        ouest: "Ouest",
                        nord_est: "Nord-Est",
                        nord_ouest: "Nord-Ouest",
                        sud_est: "Sud-Est",
                        sud_ouest: "Sud-Ouest",
                    },
                    annexes: "Annexes",
                    autres: "Autres",
                    desc_complementaire: "Descriptif complémentaire",
                },
                url: {
                    title: "URL & Web",
                    url_standard: "URL standard",
                    url_cible_immo: "URL cible immo",
                    url_social_immo: "URL social immo",
                    url_push_immo: "URL push immo",
                },
            },
            create_lots_label: {
                title: "Nouveau lot",
                criteres: {
                    title: "Critères",
                    type_bien: {
                        title: "Type de bien",
                        appart: "Appartement",
                        maison: "Maison",
                        commerce: "Commerce",
                        parking: "Parking",
                        box: "Box",
                        studio: "Studio",
                    },
                },
                info: "Vous pourrez renseigner les caractéristiques de votre lot après enregistrement.",
            },
            layout: {
                title: "Programmes Neufs",
                nb_beneficiaires: "%{smart_count} bénéficiaire |||| %{smart_count} bénéficiaires",
                compte_beneficiaire: "Compte bénéficiaire",
                compte_service: "Compte de service",
            },
            tooltip: {
                programme_rapatrie_depuis_cs: "Programme rapatrié du compte de service",
                programme_rapatrie_sur_cb: "Programme rapatrié sur un compte bénéficiaire:",
                origine_passerelle: "Passerelle %{origine}",
            },
        },
    },
};

export default messages;
