import * as React from "react";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";

export const RapatrierButton = (props: {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    label: string;
}) => (
    <Button variant="contained" color="primary" onClick={props.onClick}>
        <GetAppIcon fontSize="small" />
        &nbsp;
        {props.label}
    </Button>
);
