import * as React from "react";

export const CustomNumberField = (props: {
    source: string;
    record?: any;
    label?: string;
    sortable?: boolean;
    textAlign?: string;
    headerClassName?: string;
}) => {
    const { source, record = {} } = props;

    function number() {
        if (record[source] > 0) {
            return record[source];
        }
        return null;
    }

    return number() ? <span>{number()}</span> : null;
};
