import React, { useState } from "react";
import {
    ArrayInput,
    FormDataConsumer,
    ImageField,
    ImageInput,
    required,
    SelectInput,
    TextInput,
    useTranslate,
} from "react-admin";
import typePhotoChoices from "../../../model/constants/typePhotoChoices";
import { useImageInputStyles, useStyles } from "../formStyles";
import ReorderedFormIterator from "../../raCustom/ReorderedFormIterator";
import CustomAddButton from "../../widgets/CustomAddButton";
import CustomRemoveButton from "../../widgets/CustomRemoveButton";

const GaleriePhotoTab = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const imageInputClasses = useImageInputStyles();
    const [disableReordering, setDisableReordering] = useState(false);

    return (
        <>
            <TextInput
                className={classes.largeField}
                label={translate("lipro.edit_label.galerie_photos.url_visite_virtuelle")}
                source="urlVisiteVirtuelle"
                type="url"
            />
            <br />
            <ArrayInput source="imagesGallerie" label={translate("lipro.edit_label.galerie_photos.galerie_title")}>
                <ReorderedFormIterator
                    className={classes.galerieFormIterator}
                    disableReordering={disableReordering}
                    addButton={<CustomAddButton onClick={() => setDisableReordering(true)} />}
                    removeButton={<CustomRemoveButton />}
                >
                    <FormDataConsumer>
                        {({ getSource, scopedFormData }) => {
                            if (getSource) {
                                if (scopedFormData?.url) {
                                    return <ImageField key={getSource("url")} source={"url"} record={scopedFormData} />;
                                } else {
                                    if (scopedFormData?.base64Content) {
                                        return (
                                            <ImageField
                                                key={getSource("base64Content")}
                                                source="base64Content"
                                                record={scopedFormData}
                                            />
                                        );
                                    } else {
                                        return (
                                            <ImageInput
                                                classes={imageInputClasses}
                                                key={getSource("base64Content")}
                                                source={getSource("")}
                                                label=""
                                                placeholder={
                                                    <p>
                                                        {translate("lipro.edit_label.galerie_photos.dropzone_message")}
                                                        <br />
                                                        {translate("lipro.edit_label.galerie_photos.dropzone_format")}
                                                    </p>
                                                }
                                                accept="image/jpg, image/jpeg"
                                            >
                                                <ImageField key={getSource("base64Content")} source="base64Content" />
                                            </ImageInput>
                                        );
                                    }
                                }
                            }
                        }}
                    </FormDataConsumer>
                    <SelectInput
                        source="typeMultimedia.coTypeMult"
                        label={translate("lipro.edit_label.galerie_photos.type_multimedia.title")}
                        translateChoice
                        choices={typePhotoChoices}
                        validate={required()}
                    />
                </ReorderedFormIterator>
            </ArrayInput>
        </>
    );
};
export default GaleriePhotoTab;
