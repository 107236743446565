export const defaultCompteBeneficiaireClient = {
    idClient: 161411,
    coClient: "0001447092",
    coRcu: "RC-99999688-TEMP",
    liNom: "VINCI IMMOBILIER RESIDENTIEL",
    tyCompteEns: "B",
    ensemble: {
        idEnsemble: "V007",
        idClient: 383084,
        coClient: "0005034694",
        liNom: "VI2C",
        nbBeneficiaires: 5,
    },
};
