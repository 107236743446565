import React from "react";
import { BooleanInput, NumberInput, required, SelectInput, TextInput, useTranslate } from "react-admin";
import { Grid, Tooltip } from "@material-ui/core";
import { useStyles } from "../../biens/formStyles";
import DefiscalisationInput from "../../biens/widgets/DefiscalisationInput";
import conditionsPrixChoices from "../../../model/constants/conditionsPrixChoices";
import InfoIcon from "@material-ui/icons/Info";
import exclusiviteChoices from "../../../model/constants/exclusiviteChoices";
import QteLotInput from "../widgets/QteLotInput";
import { Lot } from "../../../model/domain/LotDomain";

const CaractLotTab = (props: { lot: Lot }) => {
    const translate = useTranslate();
    const classes = useStyles();

    const { lot } = props;

    const validateNbPiecesComplement = lot.requireNbPieces ? [required()] : [];

    return (
        <>
            {lot.needTypeDeBienBis && (
                <BooleanInput
                    className={classes.field}
                    label={translate("lipro.edit_lots_label.caract_lot.bien_bis")}
                    source="typeDeBienBis"
                />
            )}
            <BooleanInput
                className={classes.field}
                label={translate("lipro.edit_lots_label.caract_lot.copro")}
                source="coPro"
            />
            <QteLotInput {...props} />
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_lots_label.caract_lot.n_lot")}
                source="numeroLotPresente"
            />
            <br />
            {lot.needNbPiecesComplement && (
                <NumberInput
                    className={classes.field}
                    label={translate("lipro.edit_lots_label.caract_lot.nb_pieces")}
                    source="nbPiecesComplement"
                    validate={validateNbPiecesComplement}
                />
            )}
            <br />
            {lot.needSurfaceHabitable && (
                <NumberInput
                    className={classes.field}
                    label={translate("lipro.edit_lots_label.caract_lot.surface_habitable")}
                    source="surfaceHabitable"
                />
            )}
            {lot.needSurfaceTerrain && (
                <NumberInput
                    className={classes.field}
                    label={translate("lipro.edit_lots_label.caract_lot.surface_terrain")}
                    source="surfaceTerrain"
                />
            )}
            <Grid container direction="row" justify="flex-start" alignItems="baseline">
                <NumberInput
                    className={classes.field}
                    label={translate("lipro.edit_lots_label.caract_lot.prix")}
                    source="prix"
                />
                <SelectInput
                    className={classes.field}
                    label={translate("lipro.edit_lots_label.caract_lot.conditions_prix.title")}
                    source="conditionsPrix"
                    translateChoice
                    allowEmpty
                    choices={conditionsPrixChoices}
                />
                <Tooltip title={translate("lipro.edit_lots_label.caract_lot.avert_prix")} placement="right">
                    <InfoIcon />
                </Tooltip>
            </Grid>
            {lot.needDefiscalisation && (
                <DefiscalisationInput
                    label={translate("lipro.edit_lots_label.caract_lot.defiscalisation")}
                    source="dispositifDefiscalisation"
                    className={classes.field}
                />
            )}
            <br />
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_lots_label.caract_lot.secteur")}
                source="secteur"
            />
            <br />
            <SelectInput
                className={classes.field}
                label={translate("lipro.edit_lots_label.caract_lot.exclusivite.title")}
                source="exclusivite"
                translateChoice
                allowEmpty
                choices={exclusiviteChoices}
            />
            <br />
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_lots_label.caract_lot.url_visite_virtuelle")}
                source="urlVisiteVirtuelle"
            />
        </>
    );
};

export default CaractLotTab;
