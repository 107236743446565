export const RestRoutes = {
    biens: "biens",
    compteService: "compte-service",
    rapatrierProgramme: "compte-service/rapatriement",
    prestations: "prestations",
    supports: "prestations/supports",
    activeProgrammesFromWeb: "biens/activation",
    desactiveProgrammesFromWeb: "biens/desactivation",
    loginSalarie: "comptes/login",
    getClientByCode: "clients/code",
    getClientByCodeRcu: "clients/rcu",
    getClientById: "clients",
    getClientByIdSSO: "comptes",
    plaquetteCommerciale: "plaquettes-commerciales",
    getDefiscalisations: "defiscalisations",
    lots: "lots",
    pays: "pays",
    codesPostaux: "codes-postaux",
    communes: "communes",
};
