import { useTranslate } from "react-admin";
import { useStyles } from "../formStyles";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../utils/SessionUser";
import { Field, useForm } from "react-final-form";
import { Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { EditProvider } from "../../../providers/editProvider";
import { VilleType } from "../../../model/GeoTypes";

const PaysCPVilleInput = (props: { record: any; sourceCP: string; sourceVille: string; required?: boolean }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const { record, sourceCP, sourceVille, required } = props;
    const sessionUserContext = useContext(UserContext);
    const editProvider = new EditProvider(sessionUserContext);
    const form = useForm();

    const [value, setValue] = useState<VilleType>({
        coPostal: "",
        liCommune: "",
        coPays: "",
    });

    const [inputCPValue, setInputCPValue] = useState("");
    const [inputVilleValue, setInputVilleValue] = useState("");

    const [openCP, setOpenCP] = useState(false);
    const [openVille, setOpenVille] = useState(false);

    const [cpOptions, setCPOptions] = useState<VilleType[]>([]);
    const [villeOptions, setVilleOptions] = useState<VilleType[]>([]);

    const errorFallbackCPVille = (status: number) => {
        console.error("Erreur lors du chargement des CP/Villes : " + status);
    };

    useEffect(() => {
        if (record[sourceCP] && record[sourceVille]) {
            setValue({ coPostal: record[sourceCP], liCommune: record[sourceVille], coPays: "FR" });
        }
    }, [record, sourceCP, sourceVille]);

    useEffect(() => {
        if (inputCPValue.length >= 1) {
            editProvider.fetchCP("FR", inputCPValue, errorFallbackCPVille).then((villes: VilleType[]) => {
                setCPOptions(
                    villes.filter((ville: VilleType) => ville.liCommune && ville.coPostal.startsWith(inputCPValue))
                );
            });
        }
    }, [inputCPValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (inputVilleValue.length >= 1) {
            editProvider
                .fetchCommunes("FR", inputVilleValue, errorFallbackCPVille)
                .then((villes: VilleType[]) => setVilleOptions(villes));
        }
    }, [inputVilleValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!openCP) {
            setCPOptions([]);
        }
    }, [openCP]);

    useEffect(() => {
        if (!openVille) {
            setVilleOptions([]);
        }
    }, [openVille]);

    return (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Field name={sourceCP}>
                {(props) => (
                    <Autocomplete
                        disableClearable
                        open={openCP}
                        onOpen={() => {
                            setOpenCP(true);
                        }}
                        onClose={() => {
                            setOpenCP(false);
                        }}
                        getOptionSelected={(option, value) => option.coPostal === value.coPostal}
                        options={cpOptions}
                        getOptionLabel={(option: any) => option.coPostal}
                        renderOption={(option: any) => option.coPostal + " (" + option.liCommune + ")"}
                        className={classes.field}
                        renderInput={(params: any) => (
                            <TextField
                                required={required}
                                variant="filled"
                                label={translate("lipro.edit_label.infos_principales.cp")}
                                {...params}
                            />
                        )}
                        value={value}
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue(newValue);
                                props.input.onChange(newValue?.coPostal);
                                form.change(sourceVille, newValue?.liCommune);
                            }
                        }}
                        inputValue={inputCPValue}
                        onInputChange={(_, newInputValue) => {
                            setInputCPValue(newInputValue);
                        }}
                    />
                )}
            </Field>
            <Field name={sourceVille}>
                {(props) => (
                    <Autocomplete
                        disableClearable
                        open={openVille}
                        onOpen={() => {
                            setOpenVille(true);
                        }}
                        onClose={() => {
                            setOpenVille(false);
                        }}
                        options={villeOptions}
                        getOptionSelected={(option, value) => option.liCommune === value.liCommune}
                        getOptionLabel={(option: any) => option.liCommune}
                        renderOption={(option: any) => option.liCommune + " (" + option.coPostal + ")"}
                        className={classes.field}
                        renderInput={(params: any) => (
                            <TextField
                                required={required}
                                variant="filled"
                                label={translate("lipro.edit_label.infos_principales.ville")}
                                {...params}
                            />
                        )}
                        value={value}
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue(newValue);
                                props.input.onChange(newValue?.liCommune);
                                form.change(sourceCP, newValue?.coPostal);
                            }
                        }}
                        inputValue={inputVilleValue}
                        onInputChange={(_, newInputValue) => {
                            setInputVilleValue(newInputValue);
                        }}
                    />
                )}
            </Field>
        </Grid>
    );
};

export default PaysCPVilleInput;
