import React, { useContext } from "react";
import {
    Create,
    Error,
    FormDataConsumer,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    useTranslate,
} from "react-admin";
import FormCustomToolbar from "../biens/widgets/FormCustomToolbar";
import LotFormActions from "./widgets/LotFormActions";
import { useStyles } from "../biens/formStyles";
import TypeBienChoices from "../../model/constants/typeBienChoices";
import QteLotInput from "./widgets/QteLotInput";
import DescriptionLotTab from "./formTabs/DescriptionLotTab";
import { Grid } from "@material-ui/core";
import { UserContext } from "../../utils/SessionUser";

const lotDefaultValue = () => ({
    liTransaction: "Vente",
    nbPiecesComplement: 0,
    surfaceHabitable: 0,
    surfaceTerrain: 0,
    prix: 0,
});

const LotCreate = (props: any) => {
    const translate = useTranslate();
    const classes = useStyles();
    const sessionUserContext = useContext(UserContext);
    const client = sessionUserContext.getClient();

    const nbPiecesRequired = ["Maison", "Appartement"];

    // noinspection RequiredAttributes
    return client.isValid() ? (
        <Create
            mutationMode="pessimistic"
            actions={
                <LotFormActions
                    label={translate("lipro.action.revenir_lots")}
                    typeBien={translate("lipro.create_lots_label.title")}
                />
            }
            {...props}
        >
            <SimpleForm
                toolbar={<FormCustomToolbar info={translate("lipro.create_lots_label.info")} />}
                initialValues={lotDefaultValue}
            >
                <Grid container direction="row" justify="flex-start" alignItems="flex-end" style={{ width: "100%" }}>
                    <SelectInput
                        className={classes.field}
                        label={translate("lipro.create_lots_label.criteres.type_bien.title")}
                        source="liTypeBien"
                        translateChoice
                        allowEmpty
                        choices={TypeBienChoices}
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            nbPiecesRequired.includes(formData.liTypeBien) && (
                                <NumberInput
                                    className={classes.field}
                                    label={translate("lipro.edit_lots_label.caract_lot.nb_pieces")}
                                    source="nbPiecesComplement"
                                    validate={required()}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>
                </Grid>
                <QteLotInput />
                <DescriptionLotTab />
            </SimpleForm>
        </Create>
    ) : (
        <Error error={translate("lipro.notification.erreur_nouvel_onglet_form")} />
    );
};

export default LotCreate;
