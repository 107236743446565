class ProgrammeVO {
    public readonly id: number;
    public readonly rfRefClient: string;
    public readonly liNom: string;
    public readonly liCodepostal: string;
    public readonly liLocalisation: string;
    public readonly dtModif: string;
    public readonly nbStudio: number;
    public readonly nb_T1: number;
    public readonly nb_T2: number;
    public readonly nb_T3: number;
    public readonly nb_T4: number;
    public readonly nb_T5Plus: number;
    public readonly nb_maison_3: number;
    public readonly nb_maison_4: number;
    public readonly nb_maison_5: number;
    public readonly nb_maison_6plus: number;
    public readonly nb_parking: number;
    public readonly nb_box: number;
    public readonly nb_commerce: number;
    public readonly nb_lgt: number;

    private readonly _isValid: boolean;

    constructor(datum?: Record<any, any>) {
        this._isValid = datum !== null;
        this.id = (datum && datum.id) || 0;
        this.rfRefClient = (datum && datum.rfRefClient) || "";
        this.liNom = (datum && datum.liNom) || "";
        this.liCodepostal = (datum && datum.liCodepostal) || "";
        this.liLocalisation = (datum && datum.liLocalisation) || "";
        this.dtModif = (datum && datum.dtModif) || null;
        this.nbStudio = (datum && datum.nbStudio) || -2;
        this.nb_T1 = (datum && datum.nb_T1) || -2;
        this.nb_T2 = (datum && datum.nb_T2) || -2;
        this.nb_T3 = (datum && datum.nb_T3) || -2;
        this.nb_T4 = (datum && datum.nb_T4) || -2;
        this.nb_T5Plus = (datum && datum.nb_T5Plus) || -2;
        this.nb_maison_3 = (datum && datum.nb_maison_3) || -2;
        this.nb_maison_4 = (datum && datum.nb_maison_4) || -2;
        this.nb_maison_5 = (datum && datum.nb_maison_5) || -2;
        this.nb_maison_6plus = (datum && datum.nb_maison_6plus) || -2;
        this.nb_parking = (datum && datum.nb_parking) || -2;
        this.nb_box = (datum && datum.nb_box) || -2;
        this.nb_commerce = (datum && datum.nb_commerce) || -2;
        this.nb_lgt = (datum && datum.nb_lgt) || -2;
    }

    isValid() {
        return this._isValid;
    }
}

export default ProgrammeVO;
