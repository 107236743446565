import { SessionUser } from "../utils/SessionUser";

export async function doFetch(
    sessionUser: SessionUser,
    route: string,
    errorFallback: (status: number) => void,
    parameter?: string
) {
    const url = parameter
        ? process.env.REACT_APP_API_ENDPOINT + "/" + route + "/" + parameter
        : process.env.REACT_APP_API_ENDPOINT + "/" + route;
    const request = new Request(url, {
        method: "GET",
        headers: sessionUser.getAuthorizationHeader(),
    });

    const response = await fetch(request);

    if (!response.ok) {
        const status = response.status;
        errorFallback(status);
    }

    return await response.json();
}

export async function doFetchSSO(route: string, token: string, errorFallback: (status: number) => void) {
    const request = new Request(process.env.REACT_APP_API_ENDPOINT + "/" + route, {
        method: "GET",
        headers: new Headers({ idSSO: `${token}` }),
    });

    const response = await fetch(request);

    if (!response.ok) {
        const status = response.status;
        errorFallback(status);
    }

    return await response.json();
}

// Default React-Admin functions with custom error messages
export interface Options extends RequestInit {
    user?: {
        authenticated?: boolean;
        token?: string;
    };
}

const createHeadersFromOptions = (options: Options): Headers => {
    const requestHeaders = (options.headers ||
        new Headers({
            Accept: "application/json",
        })) as Headers;
    console.log("requestHeaders keys", requestHeaders.keys());
    if (
        !requestHeaders.has("Content-Type") &&
        !(options && (!options.method || options.method === "GET")) &&
        !(options && options.body && options.body instanceof FormData)
    ) {
        requestHeaders.set("Content-Type", "application/json");
    }
    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set("Authorization", options.user.token);
    }
    // patch "range" header
    if (requestHeaders.has("range")) {
        const pageRangeValue: string = requestHeaders.get("range") || "";
        requestHeaders.delete("range");
        requestHeaders.set("page-range", pageRangeValue);
    }
    return requestHeaders;
};

export const fetchJson = (url: string, options: Options = {}) => {
    const requestHeaders = createHeadersFromOptions(options);

    return fetch(url, { ...options, headers: requestHeaders })
        .then((response) =>
            response.text().then((text) => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(({ status, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
                const regexBody = /((<body>){1})(.*?)((<\/body>){1})/g; // /(?<=(<body>))(.*?)(?<=(<\/body>))/g;
                let matchBody = body.match(regexBody);
                if (matchBody) {
                    json = matchBody[0].replace("</body>", "");
                } else {
                    json = body;
                }
                return Promise.reject(new Error(json));
            }
            return Promise.resolve({ status, headers, body, json });
        });
};
