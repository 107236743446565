// Affichage de la prestation d'une diffusion PL (descriptif, quantité, période)
import { DiffuserProgrammesHandlers } from "../DiffuserProgrammesHandlers";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Confirm } from "react-admin";
import * as React from "react";

function translateQuantiteAnnonce(
    quantiteRestante: number,
    translate: (key: string, options?: any) => string,
    periode: string
) {
    return quantiteRestante > 0
        ? translate("lipro.message.menu_diffusion_detail", {
              contenu: translate("lipro.message.qt_annonce", {
                  smart_count: quantiteRestante,
              }),
          }) + periode
        : translate("lipro.message.menu_diffusion_qt_annonce_0");
}

export const DiffuserItem = (
    id: string | number,
    descriptifPrestation: string,
    quantiteRestante: number,
    translate: (key: string, options?: any) => string,
    periode: string,
    open: boolean,
    loading: boolean,
    diffuserProgrammesHandlers: DiffuserProgrammesHandlers
) => (
    <MenuItem key={id} onClick={() => diffuserProgrammesHandlers.handleClick(id, quantiteRestante)}>
        <ListItemText
            primary={descriptifPrestation}
            secondary={translateQuantiteAnnonce(quantiteRestante, translate, periode)}
        />
        <Confirm
            isOpen={open}
            loading={loading}
            title={translate("lipro.message.diffuser_programme_titre")}
            content={translate("lipro.message.diffuser_programme_contenu")}
            onConfirm={diffuserProgrammesHandlers.handleConfirm}
            onClose={diffuserProgrammesHandlers.handleDialogClose}
        />
    </MenuItem>
);
