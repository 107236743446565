import EnsembleVO from "./EnsembleVO";
import ContactVO from "./ContactVO";

class ClientVO {
    public readonly idClient: number;
    public readonly coClient: string;
    public readonly coRcu: string;
    public readonly liNom: string;
    public readonly tyCompteEns: string;
    public readonly ensemble: EnsembleVO;
    public readonly liAdresse1: string;
    public readonly liAdresse2: string;
    public readonly liAdresse3: string;
    public readonly coPostal: string;
    public readonly liVille: string;
    public readonly contactList: ContactVO[];

    private readonly _isValid: boolean;

    constructor(datum?: Record<any, any>) {
        this._isValid = datum !== null;
        this.idClient = (datum && datum.idClient) || 0;
        this.coClient = (datum && datum.coClient) || "";
        this.coRcu = (datum && datum.coRcu) || "";
        this.liNom = (datum && datum.liNom) || "";
        this.tyCompteEns = (datum && datum.tyCompteEns) || null;
        this.ensemble = (datum && datum.ensemble) || null;
        this.liAdresse1 = (datum && datum.liAdresse1) || null;
        this.liAdresse2 = (datum && datum.liAdresse2) || null;
        this.liAdresse3 = (datum && datum.liAdresse3) || null;
        this.coPostal = (datum && datum.coPostal) || null;
        this.liVille = (datum && datum.liVille) || null;
        this.contactList = (datum && datum.contactList) || [];
    }

    isValid() {
        return this._isValid;
    }
}

export default ClientVO;
