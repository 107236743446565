import * as React from "react";
import { useState } from "react";
import {
    Identifier,
    RecordMap,
    useGetList,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
    useUpdateMany,
} from "react-admin";
import { DesactiverButton } from "./widgets/DesactiverButton";
import PrestationSupportVO from "../../model/PrestationSupportVO";
import Menu from "@material-ui/core/Menu";
import { DesactiverProgrammesHandlers } from "./DesactiverProgrammesHandlers";
import { DesactivationItem } from "./widgets/DesactiverItem";
import { RestRoutes } from "../../constants/restRoutes";
import { coSupport } from "../../constants/prestations";
import { MenuItem } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";

function getDesactiverItems(
    data: RecordMap<Record<any, any>> | undefined,
    ids: Identifier[] | undefined,
    desactiverProgrammesHandlers: DesactiverProgrammesHandlers,
    translate: (key: string, options?: any) => string,
    open: boolean,
    loading: boolean
) {
    if (data == null || ids == null || ids?.length === 0) {
        return null;
    }

    const canDisplayDesactivation = (_data: RecordMap<any>) => (_id: Identifier) =>
        coSupport.includes(_data[_id].coSupport);

    return (
        <div>
            <DesactivationItem
                onClick={() => desactiverProgrammesHandlers.handleClick(null)}
                primary={translate("lipro.message.desactiver_tous_supports")}
                open={open}
                loading={loading}
                title={translate("lipro.message.desactiver_programme_titre")}
                content={translate("lipro.message.desactiver_programme_contenu")}
                desactiverProgrammesHandlers={desactiverProgrammesHandlers}
            />
            {ids.filter(canDisplayDesactivation(data)).map((id) => {
                return (
                    <DesactivationItem
                        key={id}
                        onClick={() => desactiverProgrammesHandlers.handleClick(id)}
                        primary={translate("lipro.message.desactiver_support", {
                            support: data[id].liSupport,
                        })}
                        open={open}
                        loading={loading}
                        title={translate("lipro.message.desactiver_programme_titre")}
                        content={translate("lipro.message.desactiver_programme_contenu")}
                        desactiverProgrammesHandlers={desactiverProgrammesHandlers}
                    />
                );
            })}
        </div>
    );
}

const DesactiverProgrammes = (props: { selectedIds: (string | number)[] }) => {
    const selectedIds = props.selectedIds;

    const { data, ids } = useGetList(RestRoutes.supports, { page: 1, perPage: 10 }, { field: "id", order: "ASC" }, {});

    const [supportSelected, setSupportSelected] = useState<string | number | null>("");

    const translate = useTranslate();

    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const onUpdateSuccess = () => {
        refresh();
        notify("lipro.notification.desactivation", "info", translate);
        unselectAll("biens");
    };
    const onUpdateFailure = () => notify("lipro.notification.erreur_desactivation", "warning", translate);

    const [updateMany, { loading }] = useUpdateMany(
        "biens",
        selectedIds,
        {
            desactivation: {
                programmesId: selectedIds,
                prestationSupport: supportSelected != null ? new PrestationSupportVO(data?.[supportSelected]) : null,
            },
        },
        {
            onSuccess: onUpdateSuccess,
            onFailure: onUpdateFailure,
        }
    );

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);

    const desactiverProgrammesHandlers = new DesactiverProgrammesHandlers(
        setAnchorEl,
        setOpen,
        setSupportSelected,
        updateMany
    );

    return (
        <div style={{ marginTop: "-6px" }}>
            <DesactiverButton
                onClick={desactiverProgrammesHandlers.handleOpenMenu}
                label={translate("lipro.action.desactiver")}
            />
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={desactiverProgrammesHandlers.handleClose}
            >
                {ids && ids?.length > 0 ? (
                    getDesactiverItems(data, ids, desactiverProgrammesHandlers, translate, open, loading)
                ) : (
                    <MenuItem disabled>
                        <ListItemText primary={translate("lipro.message.menu_aucune_prestation")} />
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default DesactiverProgrammes;
