import React, { useContext, useEffect, useState } from "react";
import { TextInput, useTranslate } from "react-admin";
import { UserContext } from "../../../utils/SessionUser";
import ContactVO from "../../../model/ContactVO";
import { useStyles } from "../formStyles";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useForm } from "react-final-form";

const ContactTab = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const sessionUserContext = useContext(UserContext);
    const form = useForm();

    const contactList = [new ContactVO(), ...sessionUserContext.getClient().contactList];
    const [contact, setContact] = useState<ContactVO>(new ContactVO());

    const getContactById = (idContact: number) => {
        let contact = contactList.filter((contact) => contact.idContact === idContact);
        return contact.length > 0 ? contact[0] : null;
    };

    const handleContact = (event: any) => {
        let newContact = getContactById(event.target.value);
        if (newContact) setContact(newContact);
    };

    useEffect(() => {
        form.change("contactNom", contact.liNom);
        form.change("contactTel", contact.noTel);
        form.change("contactFax", contact.noFax);
        form.change("contactEmail", contact.liEmail);
        form.change("contactUrl", contact.liSite);
    }, [contact, form]);

    return (
        <>
            <FormControl fullWidth>
                <InputLabel className={classes.labelField}>{translate("lipro.edit_label.contact.contacts")}</InputLabel>
                <Select
                    variant="filled"
                    className={classes.contactSelectField}
                    value={contact.idContact}
                    onChange={handleContact}
                >
                    {contactList.map((contact) => (
                        <MenuItem key={contact.idContact} value={contact.idContact}>
                            {contact.liObservation}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br />
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_label.contact.nom")}
                source="contactNom"
            />
            <br />
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_label.contact.telephone")}
                source="contactTel"
            />
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_label.contact.fax")}
                source="contactFax"
            />
            <br />
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_label.contact.email")}
                source="contactEmail"
                type="email"
            />
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_label.contact.site_web")}
                source="contactUrl"
                type="url"
            />
        </>
    );
};

export default ContactTab;
