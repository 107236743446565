import { makeStyles } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

export type BienFormStyles = ClassNameMap<
    "field" | "largeField" | "contactSelectField" | "leftIcon" | "image" | "labelField" | "galerieFormIterator"
>;

export const useStyles: (props?: any) => BienFormStyles = makeStyles((theme) => ({
    field: {
        marginRight: theme.spacing(1),
        width: "25%",
    },
    largeField: {
        marginRight: theme.spacing(1),
        width: `calc(50% + ${theme.spacing(1)}px)`,
    },
    contactSelectField: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        width: "25%",
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    image: {
        margin: "0.5rem",
        maxHeight: "10rem",
    },
    labelField: {
        marginLeft: theme.spacing(1),
    },
    galerieFormIterator: {
        flex: 2,
        display: "grid",
        gridTemplateColumns: "fit-content(33%) 1fr",
        gridGap: "1rem",
        alignItems: "center",
    },
}));

export const useImageInputStyles = makeStyles((theme) => ({
    dropZone: {
        background: theme.palette.background.default,
        cursor: "pointer",
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.getContrastText(theme.palette.background.default),
        border: "1px dotted",
        width: "20 rem",
        margin: "0.5rem",
    },
    removeButton: {
        position: "relative",
        float: "left",
        "& button": {
            position: "absolute",
            top: theme.spacing(1),
            right: theme.spacing(1),
            minWidth: theme.spacing(2),
            opacity: 0,
        },
        "&:hover button": {
            opacity: 1,
        },
    },
}));
