import * as React from "react";
import Button from "@material-ui/core/Button";
import BlockIcon from "@material-ui/icons/Block";

export const DesactiverButton = (props: {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    label: string;
}) => (
    <Button
        onClick={props.onClick}
        variant="contained"
        style={{ backgroundColor: "red", color: "white", marginLeft: "10px" }}
    >
        <BlockIcon fontSize="small" />
        &nbsp;
        {props.label}
    </Button>
);
