const ExpositionChoices = [
    {
        id: "N",
        name: "lipro.edit_lots_label.caract_complementaires.exposition.nord",
    },
    {
        id: "S",
        name: "lipro.edit_lots_label.caract_complementaires.exposition.sud",
    },
    {
        id: "E",
        name: "lipro.edit_lots_label.caract_complementaires.exposition.est",
    },
    {
        id: "O",
        name: "lipro.edit_lots_label.caract_complementaires.exposition.ouest",
    },
    {
        id: "NE",
        name: "lipro.edit_lots_label.caract_complementaires.exposition.nord_est",
    },
    {
        id: "NO",
        name: "lipro.edit_lots_label.caract_complementaires.exposition.nord_ouest",
    },
    {
        id: "SE",
        name: "lipro.edit_lots_label.caract_complementaires.exposition.sud_est",
    },
    {
        id: "SO",
        name: "lipro.edit_lots_label.caract_complementaires.exposition.sud_ouest",
    },
];

export default ExpositionChoices;
