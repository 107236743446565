const UsageLogementChoices = [
    {
        id: "Résidence principale",
        name: "lipro.edit_label.infos_complementaires.usage_logement.residence_principale",
    },
    {
        id: "Résidence services",
        name: "lipro.edit_label.infos_complementaires.usage_logement.residence_services",
    },
    {
        id: "Résidence d'affaires",
        name: "lipro.edit_label.infos_complementaires.usage_logement.residence_affaires",
    },
    {
        id: "Résidence secondaire / tourisme",
        name: "lipro.edit_label.infos_complementaires.usage_logement.residence_secondaire",
    },
    {
        id: "Résidence étudiante",
        name: "lipro.edit_label.infos_complementaires.usage_logement.residence_etudiante",
    },
    {
        id: "Résidence séniors",
        name: "lipro.edit_label.infos_complementaires.usage_logement.residence_seniors",
    },
];

export default UsageLogementChoices;
