import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../utils/SessionUser";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslate } from "react-admin";
import { ClientProvider } from "../../../providers/clientProvider";
import ClientVO from "../../../model/ClientVO";
import { makeStyles } from "@material-ui/core/styles";
import SyncAltIcon from "@material-ui/icons/SyncAlt";

const useStyles = makeStyles((_) => ({
    parent: {
        display: "grid",
        placeItems: "center",
    },
    pink: {
        cursor: "default",
        borderRadius: "50%",
        border: "3px solid #f6b4bd",
        backgroundColor: "#f6b4bd",
    },
    blue: {
        color: "#0986e6",
    },
}));

export const OrigineField = (props: { source: string; record?: any; label?: string; sortable?: boolean }) => {
    const { source, record = {} } = props;
    const sessionUserContext = useContext(UserContext);
    const translate = useTranslate();
    const classes = useStyles();
    const [clientBeneficiaire, setClientBeneficiaire] = useState<ClientVO>(new ClientVO());

    useEffect(() => {
        if (sessionUserContext.isCompteService() && record?.idClientBenef) {
            const clientProvider = new ClientProvider(sessionUserContext);
            clientProvider
                .fetchClientById(record?.idClientBenef, errorFallback)
                .then((client) => setClientBeneficiaire(new ClientVO(client)));
        }
    }, [setClientBeneficiaire, record?.idClientBenef, sessionUserContext]);

    const errorFallback = (status: number) => {
        console.error("Erreur lors du chargement du compte bénéficiaire : " + status);
    };

    const getFieldAppended = <K extends keyof ClientVO>(
        clientBeneficiaire: ClientVO,
        fieldName: K,
        append: JSX.Element = <></>
    ): JSX.Element => {
        if (clientBeneficiaire === null || clientBeneficiaire[fieldName] === null) {
            return <></>;
        }
        return (
            <>
                {clientBeneficiaire[fieldName]} {append}
            </>
        );
    };

    const displayOrigine = () => {
        if (sessionUserContext.isBeneficiaire() && record?.idClientBenef) {
            return (
                <Tooltip title={translate("lipro.tooltip.programme_rapatrie_depuis_cs")} arrow>
                    <div className={classes.pink}>CS</div>
                </Tooltip>
            );
        }

        if (sessionUserContext.isCompteService() && record?.idClientBenef) {
            const tooltip = (
                <p>
                    {translate("lipro.tooltip.programme_rapatrie_sur_cb")}
                    {clientBeneficiaire.idClient !== 0 && (
                        <p>
                            {clientBeneficiaire.liNom} ({clientBeneficiaire.coClient})
                            <br />
                            {getFieldAppended(clientBeneficiaire, "liAdresse1", <br />)}
                            {getFieldAppended(clientBeneficiaire, "liAdresse2", <br />)}
                            {getFieldAppended(clientBeneficiaire, "liAdresse3", <br />)}
                            {getFieldAppended(clientBeneficiaire, "coPostal")}
                            {getFieldAppended(clientBeneficiaire, "liVille")}
                        </p>
                    )}
                </p>
            );

            return (
                <Tooltip title={tooltip} arrow>
                    <div className={classes.pink}>CB</div>
                </Tooltip>
            );
        }

        if (record[source] === "EXTRANET") {
            return (
                <Tooltip title={record[source]} arrow>
                    <img alt={"LI"} src={"iconLINeuf.ico"} width={16} height={16} />
                </Tooltip>
            );
        } else {
            return (
                <Tooltip title={translate("lipro.tooltip.origine_passerelle", { origine: record[source] })} arrow>
                    <SyncAltIcon className={classes.blue} />
                </Tooltip>
            );
        }
    };

    return <div className={classes.parent}>{displayOrigine()}</div>;
};
