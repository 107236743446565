const PhaseCommercialeChoices = [
    {
        id: "Avant première",
        name: "lipro.edit_label.infos_principales.phase_commerciale.avant_premiere",
    },
    {
        id: "Lancement commercial",
        name: "lipro.edit_label.infos_principales.phase_commerciale.lancement_commercial",
    },
    {
        id: "Démarrage des travaux",
        name: "lipro.edit_label.infos_principales.phase_commerciale.demarrage_travaux",
    },
    {
        id: "Travaux en cours",
        name: "lipro.edit_label.infos_principales.phase_commerciale.travaux_en_cours",
    },
    {
        id: "Dernière opportunité",
        name: "lipro.edit_label.infos_principales.phase_commerciale.derniere_opportunite",
    },
    {
        id: "Fin de programme",
        name: "lipro.edit_label.infos_principales.phase_commerciale.fin_programme",
    },
];

export default PhaseCommercialeChoices;
