import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import * as React from "react";
import { ReactElement } from "react";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
    chip: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}));

export const ComplementsField = (props: {
    source?: string;
    record?: any;
    label?: string | ReactElement;
    sortable?: boolean;
}) => {
    const { record = {} } = props;
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <>
            {record?.accesHandicape && (
                <Chip label={translate("lipro.grid_lots_label.complements.acces_handicape")} className={classes.chip} />
            )}
            {record?.ascenceur && (
                <Chip label={translate("lipro.grid_lots_label.complements.ascenceur")} className={classes.chip} />
            )}
            {record?.balcon && (
                <Chip label={translate("lipro.grid_lots_label.complements.balcon")} className={classes.chip} />
            )}
            {record?.climatisation && (
                <Chip label={translate("lipro.grid_lots_label.complements.climatisation")} className={classes.chip} />
            )}
            {record?.duplex && (
                <Chip label={translate("lipro.grid_lots_label.complements.duplex")} className={classes.chip} />
            )}
            {record?.meuble && (
                <Chip label={translate("lipro.grid_lots_label.complements.meuble")} className={classes.chip} />
            )}
            {record?.piscine && (
                <Chip label={translate("lipro.grid_lots_label.complements.piscine")} className={classes.chip} />
            )}
            {record?.terrasse && (
                <Chip label={translate("lipro.grid_lots_label.complements.terrasse")} className={classes.chip} />
            )}
            {record?.box && (
                <Chip label={translate("lipro.grid_lots_label.complements.box")} className={classes.chip} />
            )}
            {record?.cave && (
                <Chip label={translate("lipro.grid_lots_label.complements.cave")} className={classes.chip} />
            )}
            {record?.garage && (
                <Chip label={translate("lipro.grid_lots_label.complements.garage")} className={classes.chip} />
            )}
            {record?.parking && (
                <Chip label={translate("lipro.grid_lots_label.complements.parking")} className={classes.chip} />
            )}
            {record?.cellier && (
                <Chip label={translate("lipro.grid_lots_label.complements.cellier")} className={classes.chip} />
            )}
            {record?.parkingExt && (
                <Chip label={translate("lipro.grid_lots_label.complements.parking_ext")} className={classes.chip} />
            )}
            {record?.parkingInt && (
                <Chip label={translate("lipro.grid_lots_label.complements.parking_int")} className={classes.chip} />
            )}
            {record?.log && (
                <Chip label={translate("lipro.grid_lots_label.complements.loggia")} className={classes.chip} />
            )}
            {record?.jardin && (
                <Chip label={translate("lipro.grid_lots_label.complements.jardin")} className={classes.chip} />
            )}
        </>
    );
};
