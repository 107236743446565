import { makeStyles } from "@material-ui/core/styles";
import UpdateIcon from "@material-ui/icons/Update";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import * as React from "react";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((_) => ({
    parent: {
        display: "grid",
        placeItems: "center",
    },
    green: {
        color: "#21b047",
    },
    blue: {
        color: "#0986e6",
    },
}));

export const PLField = (props: { source: string; record?: any; label?: string; sortable?: boolean }) => {
    const { source, record = {} } = props;

    const classes = useStyles();

    const displayFlagPL = () => {
        switch (record[source]) {
            case "A":
                return (
                    <Tooltip title="PL actif" arrow>
                        <CheckCircleIcon className={classes.green} />
                    </Tooltip>
                );
            case "P":
                return (
                    <Tooltip title="PL programmé" arrow>
                        <UpdateIcon className={classes.blue} />
                    </Tooltip>
                );
            default:
                return null;
        }
    };

    return <div className={classes.parent}>{displayFlagPL()}</div>;
};
