export class RapatrierProgrammesHandlers {
    setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
    updateMany: (query?: Partial<any>, options?: Partial<any>) => void;

    constructor(
        setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void,
        updateMany: (query?: Partial<any>, options?: Partial<any>) => void
    ) {
        this.setOpen = setOpen;
        this.updateMany = updateMany;
    }

    public handleClick() {
        this.setOpen(true);
    }

    public handleConfirm() {
        this.updateMany();
        this.setOpen(false);
    }

    public handleDialogClose() {
        this.setOpen(false);
    }
}
