import React from "react";
import { NumberInput, TextInput, useTranslate } from "react-admin";
import { useStyles } from "../formStyles";
import PaysCPVilleInput from "../widgets/PaysCPVilleInput";

const BureauVenteTab = (props: any) => {
    const translate = useTranslate();
    const classes = useStyles();
    const { record } = props;

    return (
        <>
            <PaysCPVilleInput record={record} sourceCP="codePostalBureauVente" sourceVille="villeBureauVente" />
            <TextInput
                className={classes.largeField}
                label={translate("lipro.edit_label.bureau_vente.adresse")}
                source="adresseBureauVente"
            />
            <br />
            <NumberInput
                className={classes.field}
                label={translate("lipro.edit_label.bureau_vente.gps_latitude")}
                source="latitudeBureauVente"
            />
            <NumberInput
                className={classes.field}
                label={translate("lipro.edit_label.bureau_vente.gps_longitude")}
                source="longitudeBureauVente"
            />
            <br />
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_label.bureau_vente.telephone")}
                source="telephoneBureauVente"
            />
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_label.bureau_vente.email")}
                source="mailBureauVente"
                type="email"
            />
            <br />
            <TextInput
                multiline
                className={classes.largeField}
                label={translate("lipro.edit_label.bureau_vente.horaires_ouverture")}
                source="horairesBureauVente"
            />
            <br />
            <TextInput
                multiline
                rows={5}
                className={classes.largeField}
                label={translate("lipro.edit_label.bureau_vente.infos_complementaires")}
                source="descriptionBureauVente"
            />
        </>
    );
};

export default BureauVenteTab;
