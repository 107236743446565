import * as React from "react";
import { Fragment, useContext } from "react";
import { Datagrid, EditButton, Error, List, NumberField, useTranslate } from "react-admin";
import { ProgrammeField } from "../biens/widgets/ProgrammeField";
import { ComplementsField } from "./widgets/ComplementsField";
import { CustomNumberField } from "./widgets/CustomNumberField";
import { TopToolbarLots } from "./widgets/TopToolbarLots";
import { UserContext } from "../../utils/SessionUser";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    numberColumn: {
        textAlign: "right",
    },
}));

const LotList = (props: any) => {
    const classes = useStyles();
    const translate = useTranslate();
    const sessionUserContext = useContext(UserContext);
    const client = sessionUserContext.getClient();

    return client.isValid() ? (
        <Fragment>
            <List {...props} actions={<TopToolbarLots />} pagination={false} empty={false}>
                <Datagrid>
                    <CustomNumberField
                        label={translate("lipro.grid_lots_label.quantite")}
                        source="qtLot"
                        textAlign="right"
                        sortable={false}
                        headerClassName={classes.numberColumn}
                    />
                    <ProgrammeField
                        label={translate("lipro.grid_lots_label.type")}
                        source="liTypeBien"
                        sortable={false}
                    />
                    <CustomNumberField
                        label={translate("lipro.grid_lots_label.pieces")}
                        source="nbPieces"
                        textAlign="right"
                        sortable={false}
                        headerClassName={classes.numberColumn}
                    />
                    <CustomNumberField
                        label={translate("lipro.grid_lots_label.surface")}
                        source="vlSurface"
                        textAlign="right"
                        sortable={false}
                        headerClassName={classes.numberColumn}
                    />
                    <CustomNumberField
                        label={translate("lipro.grid_lots_label.prix")}
                        source="vlPrix"
                        textAlign="right"
                        sortable={false}
                        headerClassName={classes.numberColumn}
                    />
                    <ComplementsField label={translate("lipro.grid_lots_label.complements.title")} sortable={false} />
                    <NumberField
                        label={translate("lipro.grid_lots_label.photos")}
                        source="nbPhotosWeb"
                        sortable={false}
                        headerClassName={classes.numberColumn}
                    />
                    <EditButton label={""} />
                </Datagrid>
            </List>
        </Fragment>
    ) : (
        <Error error={translate("lipro.notification.erreur_nouvel_onglet_lot")} />
    );
};

export default LotList;
