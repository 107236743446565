import React, { useState } from "react";
import { Admin, Resource } from "react-admin";
import BienList from "./components/biens/BienList";
import AppLayout from "./components/layout/AppLayout";
import i18nProvider from "./providers/i18nProvider";
import { RestRoutes } from "./constants/restRoutes";
import customRoutes from "./routes";
import { createHashHistory as createHistory } from "history";
import { simpleRestClientBuilder } from "./providers/customDataProvider";
import { AuthProvider } from "./providers/authProvider";
import { sessionUser, UserContext } from "./utils/SessionUser";
import BienCompteServiceList from "./components/biensCompteService/BienCompteServiceList";
import { BienEdit } from "./components/biens/BienEdit";
import { BienCreate } from "./components/biens/BienCreate";
import LotList from "./components/lots/LotList";
import LotEdit from "./components/lots/LotEdit";
import LotCreate from "./components/lots/LotCreate";

const history = createHistory();
const authProvider = new AuthProvider(sessionUser);
const dataProvider = simpleRestClientBuilder(sessionUser);

function getAdmin(idCompteService: number) {
    return (
        <Admin
            // @ts-ignore
            authProvider={authProvider}
            // @ts-ignore
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            layout={AppLayout}
            customRoutes={customRoutes}
            history={history}
        >
            <Resource name={RestRoutes.biens} list={BienList} edit={BienEdit} create={BienCreate} />
            <Resource name={RestRoutes.lots} list={LotList} edit={LotEdit} create={LotCreate} />
            <Resource
                name={`${RestRoutes.compteService}/${idCompteService}/${RestRoutes.biens}`}
                list={BienCompteServiceList}
            />
            <Resource name={RestRoutes.prestations} />
            <Resource name={RestRoutes.activeProgrammesFromWeb} />
            <Resource name={RestRoutes.desactiveProgrammesFromWeb} />
            <Resource name={RestRoutes.supports} />
        </Admin>
    );
}

const App = () => {
    const [idCompteService, setIdCompteService] = useState<number>(sessionUser.getIdCompteService());
    sessionUser.setIdCompteServiceDispatcher(setIdCompteService);
    return <UserContext.Provider value={sessionUser}>{getAdmin(idCompteService)}</UserContext.Provider>;
};
export default App;
