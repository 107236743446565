import * as React from "react";

export class DesactiverProgrammesHandlers {
    setAnchorEl: (currentTarget: (EventTarget & HTMLButtonElement) | null) => void;
    setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
    setSupportSelected: (value: string | number | null) => void;
    updateMany: (query?: Partial<any>, options?: Partial<any>) => void;

    constructor(
        setAnchorEl: (currentTarget: (EventTarget & HTMLButtonElement) | null) => void,
        setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void,
        setSupportSelected: (value: string | number | null) => void,
        updateMany: (query?: Partial<any>, options?: Partial<any>) => void
    ) {
        this.setAnchorEl = setAnchorEl;
        this.setOpen = setOpen;
        this.setSupportSelected = setSupportSelected;
        this.updateMany = updateMany;
    }

    public handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setAnchorEl(event.currentTarget);
    };

    public handleClose = () => {
        this.setAnchorEl(null);
    };

    public handleClick = (id: string | number | null) => {
        this.setOpen(true);
        this.setSupportSelected(id);
    };

    public handleDialogClose = () => this.setOpen(false);

    public handleConfirm = () => {
        this.updateMany();
        this.setOpen(false);
    };
}
