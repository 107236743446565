import UserVO from "../model/UserVO";
import ClientVO from "../model/ClientVO";
import Cookies from "universal-cookie/es6";
import React from "react";
import { Identifier } from "react-admin";

const SESSION_PREFIX = "MYSELOGERPRO_P9";

const prefixSession = (prop: string) => `${SESSION_PREFIX}.${prop.toLowerCase()}`;

export class SessionUser {
    public langue = "FR";
    private authCookies = new Cookies();

    removeSession() {
        Object.keys(localStorage).forEach((key) => key.includes(SESSION_PREFIX) && localStorage.removeItem(key));
        Object.keys(sessionStorage).forEach((key) => key.includes(SESSION_PREFIX) && sessionStorage.removeItem(key));
        this.authCookies.remove(prefixSession("auth"));
    }

    setUser(userVO: UserVO) {
        this.authCookies.set(prefixSession("auth"), JSON.stringify(userVO));
    }

    getUser(): UserVO {
        return this.authCookies.get(prefixSession("auth"));
    }

    deleteUser() {
        sessionStorage.removeItem(prefixSession("client"));
        this.authCookies.remove(prefixSession("auth"));
    }

    getToken() {
        let token = "";
        if (this.authCookies.get(prefixSession("auth"))) token = this.authCookies.get(prefixSession("auth")).token;
        return token;
    }

    setClient(jsonClient: Record<any, any>) {
        const clientVO = new ClientVO(jsonClient);
        sessionStorage.setItem(prefixSession("client"), JSON.stringify(clientVO));
        if (this.isBeneficiaire()) {
            this.setIdCompteService(clientVO.ensemble.idClient);
        }
    }

    getClient(): ClientVO {
        const item = sessionStorage.getItem(prefixSession("client")) as string;
        return new ClientVO(JSON.parse(item));
    }

    setIdProgramme(idProgramme: Identifier) {
        sessionStorage.setItem(prefixSession("idProgramme"), JSON.stringify(idProgramme));
    }

    getIdProgramme(): Identifier {
        return sessionStorage.getItem(prefixSession("idProgramme")) as Identifier;
    }

    getAuthorizationHeader() {
        const token = this.getToken() as string;
        return new Headers({ Authorization: `${token}` });
    }

    getAuthorizationHeaderWithIdClient() {
        const token = this.getToken() as string;
        const client = this.getClient();
        if (client.isValid())
            return new Headers({
                Authorization: `${token}`,
                IdClient: `${client.idClient}`,
                "Content-Type": "application/json",
            });
        return new Headers();
    }

    isBeneficiaire() {
        const client = this.getClient();
        return client?.tyCompteEns === "B";
    }

    isCompteService() {
        const client = this.getClient();
        return client?.tyCompteEns === "S";
    }

    setIdCompteServiceDispatcher(dispatcher: React.Dispatch<React.SetStateAction<number>>) {
        this.setIdCompteService = dispatcher;
    }

    getIdCompteService() {
        let idCompteService = 0;
        if (this.isBeneficiaire()) {
            const client = this.getClient();
            idCompteService = client.ensemble.idClient;
        }
        return idCompteService;
    }

    isSalarie() {
        const user = this.getUser();
        return user?.typeUser === "SALARIE";
    }

    private setIdCompteService: React.Dispatch<React.SetStateAction<number>> = (_: React.SetStateAction<number>) => {};
}

export const sessionUser = new SessionUser();
export const UserContext = React.createContext(sessionUser);
