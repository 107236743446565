import { RestRoutes } from "../constants/restRoutes";
import { LoginInformation } from "../utils/LoginInformation";
import UserVO from "../model/UserVO";
import { SessionUser } from "../utils/SessionUser";
import { defaultCompteBeneficiaireClient } from "../fixtures/defaultCompteBeneficiaireClient";

//import { defaultCompteServiceClient } from "../fixtures/defaultCompteServiceClient";

export class AuthProvider {
    private sessionUser: SessionUser;

    constructor(sessionUser: SessionUser) {
        this.sessionUser = sessionUser;
    }

    async login(loginInformations: LoginInformation) {
        let userVO: UserVO;
        if (
            process.env.REACT_APP_DEMO_USER_NAME &&
            loginInformations.username === process.env.REACT_APP_DEMO_USER_NAME
        ) {
            userVO = this.loginUserDemo();
        } else {
            userVO = await AuthProvider.retrieveUserSalarie(loginInformations);
        }
        this.sessionUser.setUser(userVO);
    }

    private static async retrieveUserSalarie(loginInformations: LoginInformation) {
        const request = new Request(process.env.REACT_APP_API_ENDPOINT + "/" + RestRoutes.loginSalarie, {
            method: "POST",
            body: JSON.stringify({
                login: loginInformations.username,
                password: loginInformations.password,
            }),
            headers: new Headers({ "Content-Type": "application/json" }),
        });
        const response = await fetch(request);
        if (response.status === 403) {
            throw new Error("Accès refusé : veuillez vérifier votre identifiant et votre mot de passe");
        }
        if (response.status < 200 || response.status >= 300) {
            throw new Error("Erreur réseau : " + response.statusText);
        }
        const auth = await response.json();
        return new UserVO(auth);
    }

    private loginUserDemo() {
        const compteUtilisateur = defaultCompteBeneficiaireClient;
        const userVO = new UserVO({
            nom: compteUtilisateur.liNom,
            token: process.env.REACT_APP_DEMO_USER_JWT_TOKEN,
            typeUser: "SALARIE",
            code: compteUtilisateur.coClient,
        });
        this.sessionUser.setClient(compteUtilisateur);
        return userVO;
    }

    checkError(error: { status: any }) {
        const status = error.status;
        if (status === 401 || status === 403) {
            this.sessionUser.deleteUser();
            return Promise.reject();
        }
        return Promise.resolve();
    }

    checkAuth() {
        return this.sessionUser.getUser() ? Promise.resolve() : Promise.reject();
    }

    logout() {
        this.sessionUser.deleteUser();
        return Promise.resolve();
    }

    getIdentity() {
        try {
            const user = this.sessionUser.getUser();
            if (user) {
                return Promise.resolve({ id: user.id });
            } else {
                return Promise.resolve({});
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

    getPermissions() {
        const token = this.sessionUser.getToken();
        return token ? Promise.resolve(token) : Promise.reject();
    }
}
