import { Layout, LoadingIndicator, UserMenu, useTranslate } from "react-admin";
import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { UserContext } from "../../utils/SessionUser";
import { ClientTextField } from "../widgets/ClientTextField";
import ClientVO from "../../model/ClientVO";
import { ClientEnsembleUtilisateur, Utilisateur } from "../../model/domain/UserDomain";
import { useHistory } from "react-router-dom";
import UserVO from "../../model/UserVO";
import { version } from "../../../package.json";
import { runDidomi as runDidomiProduction } from "./../../cmp";
import { runDidomi as runDidomiDevelopment } from "./../../cmp.local";

const useStyles = makeStyles({
    spacer: {
        flex: 1,
    },
    logo: {
        margin: "20px",
        width: "auto",
        height: "50px",
    },
    appBar: {
        marginBottom: "50px !important",
    },
    sidebar: {
        margin: "10px",
    },
    application_version: {
        paddingTop: "16px",
        fontSize: "12px",
    },
});

interface UtilisateurAppLayout extends Utilisateur {
    getRechercheClient: () => JSX.Element;

    getAffichageCompte: () => String;

    getAffichageCompteComplet: () => JSX.Element;
}

abstract class ClientEnsembleUtilisateurAppLayout extends ClientEnsembleUtilisateur implements UtilisateurAppLayout {
    protected translate: (key: string, options?: any) => string;
    private readonly typeUser: String;
    private readonly enableRechercheClient: Boolean;

    constructor(
        client: ClientVO,
        typeUser: String,
        enableRechercheClient: Boolean,
        translate: (key: string, options?: any) => string
    ) {
        super(client);
        this.typeUser = typeUser;
        this.enableRechercheClient = enableRechercheClient;
        this.translate = translate;
    }

    abstract getAffichageCompte(): String;

    abstract getAffichageCompteComplet(): JSX.Element;

    getRechercheClient(): JSX.Element {
        if (this.typeUser === "SALARIE" && this.enableRechercheClient) {
            return <ClientTextField label={"coClient/coRCU"} />;
        }
        return <></>;
    }
}

class CompteService extends ClientEnsembleUtilisateurAppLayout {
    getAffichageCompte(): String {
        return this.liNom + (!this.coClient || this.coClient === "" ? "" : " (" + this.coClient + ")");
    }

    getAffichageCompteComplet(): JSX.Element {
        return (
            <Typography variant="h6" color="inherit">
                {this.getAffichageCompte()}
                <Typography color="inherit">
                    {this.translate("lipro.layout.nb_beneficiaires", {
                        smart_count: this.ensemble.nbBeneficiaires,
                    })}
                </Typography>
            </Typography>
        );
    }
}

class CompteBeneficiaire extends ClientEnsembleUtilisateurAppLayout {
    getAffichageCompte(): String {
        return (
            this.translate("lipro.layout.compte_beneficiaire") +
            " : " +
            this.liNom +
            (!this.coClient || this.coClient === "" ? "" : " (" + this.coClient + ")")
        );
    }

    getAffichageCompteComplet(): JSX.Element {
        return (
            <Typography color="inherit">
                {this.getAffichageCompte()} <br />
                {this.translate("lipro.layout.compte_service")} : {this.ensemble.liNom} ({this.ensemble.coClient})
            </Typography>
        );
    }
}

class UtilisateurClient implements UtilisateurAppLayout {
    coClient: String;
    liNom: String;
    private readonly typeUser: String;
    private readonly enableRechercheClient: Boolean;

    constructor(client: ClientVO, typeUser: String, enableRechercheClient: Boolean) {
        this.coClient = client.coClient;
        this.liNom = client.liNom;
        this.typeUser = typeUser;
        this.enableRechercheClient = enableRechercheClient;
    }

    getRechercheClient(): JSX.Element {
        if (this.typeUser === "SALARIE" && this.enableRechercheClient) {
            return <ClientTextField label={"coClient/coRCU"} />;
        }
        return <></>;
    }

    getAffichageCompte(): String {
        return this.liNom + (!this.coClient || this.coClient === "" ? "" : " (" + this.coClient + ")");
    }

    getAffichageCompteComplet(): JSX.Element {
        return (
            <Typography variant="h6" color="inherit">
                {this.getAffichageCompte()}
            </Typography>
        );
    }
}

const buildUtilisateurAppLayout: (
    client: ClientVO,
    user: UserVO,
    translate: (key: string, options?: any) => string,
    enableRechercheClient: boolean
) => UtilisateurAppLayout = (
    client: ClientVO,
    user: UserVO,
    translate: (key: string, options?: any) => string,
    enableRechercheClient: boolean
) => {
    switch (client.tyCompteEns) {
        case "B":
            return new CompteBeneficiaire(client, user?.typeUser, enableRechercheClient, translate);
        case "S":
            return new CompteService(client, user?.typeUser, enableRechercheClient, translate);
        default:
            return new UtilisateurClient(client, user?.typeUser, enableRechercheClient);
    }
};

const LiProAppBar = (props: any) => {
    const classes = useStyles();
    const sessionUser = useContext(UserContext);
    const translate = useTranslate();
    const history = useHistory();

    let utilisateur = buildUtilisateurAppLayout(
        sessionUser.getClient(),
        sessionUser.getUser(),
        translate,
        history.location.pathname === "/biens"
    );

    return (
        <div className={classes.appBar}>
            <AppBar {...props} color={"default"} title={translate("lipro.layout.title")}>
                <Toolbar>
                    <img src={"logo_lineuf.svg"} alt="LINEUF" className={classes.logo} />
                    {utilisateur.getRechercheClient()}
                    <span className={classes.spacer} />
                    <LoadingIndicator />
                    {utilisateur.getAffichageCompteComplet()}
                    <UserMenu {...props} />
                </Toolbar>
            </AppBar>
        </div>
    );
};

const AppLayout = ({ children, ...props }: any) => {
    const classes = useStyles();
    const EmptySidebar = () => {
        return <div className={classes.sidebar} />;
    };

    const openDidomiPreferences = () => {
        if ((window as any).Didomi) {
            (window as any).Didomi.preferences.show();
        }
    };

    useEffect(() => {
        // Didomi
        if ("development" === process.env.NODE_ENV) {
            runDidomiDevelopment();
        } else {
            runDidomiProduction();
        }
    }, []);

    return (
        <Layout {...props} appBar={LiProAppBar} sidebar={EmptySidebar}>
            {children}
            <div
                style={{
                    marginTop: "16px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <span>
                    <Link href="#" onClick={openDidomiPreferences}>
                        Paramétrer mes cookies
                    </Link>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <Link href="https://myselogerpro.com/assets/cgu.pdf" target="blank">
                        CGU
                    </Link>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <Link href="https://myselogerpro.com/assets/confidentialite.pdf" target="blank">
                        Confidentialité
                    </Link>
                </span>
                <span>version {version}</span>
            </div>
        </Layout>
    );
};

export default AppLayout;
