import { ListButton, TopToolbar } from "react-admin";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BandeauProgramme from "./BandeauProgramme";

const useStyle = makeStyles(() => ({
    formTopToolbar: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
}));

const LotFormActions = (props: { basePath?: string; label: string; typeBien?: string }) => {
    const classes = useStyle();

    return (
        <TopToolbar className={classes.formTopToolbar}>
            <BandeauProgramme {...(props as { typeBien?: string })}>
                <ListButton basePath={props.basePath} label={props.label} />
            </BandeauProgramme>
        </TopToolbar>
    );
};

export default LotFormActions;
