const dpeHabitationChoices = [
    {
        id: "NC",
        name: "lipro.edit_label.infos_complementaires.dpe.non_communique",
        icon: null,
    },
    {
        id: "NS",
        name: "lipro.edit_label.infos_complementaires.dpe.non_soumis",
        icon: null,
    },
    {
        id: "VI",
        name: "lipro.edit_label.infos_complementaires.dpe.vierge",
        icon: null,
    },
    {
        id: "A",
        name: "lipro.edit_label.infos_complementaires.dpe.A",
        icon: "DPE/DPE_A.png",
    },
    {
        id: "B",
        name: "lipro.edit_label.infos_complementaires.dpe.B",
        icon: "DPE/DPE_B.png",
    },
    {
        id: "C",
        name: "lipro.edit_label.infos_complementaires.dpe.C",
        icon: "DPE/DPE_C.png",
    },
    {
        id: "D",
        name: "lipro.edit_label.infos_complementaires.dpe.D",
        icon: "DPE/DPE_D.png",
    },
    {
        id: "E",
        name: "lipro.edit_label.infos_complementaires.dpe.E",
        icon: "DPE/DPE_E.png",
    },
    {
        id: "F",
        name: "lipro.edit_label.infos_complementaires.dpe.F",
        icon: "DPE/DPE_F.png",
    },
    {
        id: "G",
        name: "lipro.edit_label.infos_complementaires.dpe.G",
        icon: "DPE/DPE_G.png",
    },
];

export default dpeHabitationChoices;
