import * as React from "react";
import { Fragment } from "react";
import { Datagrid, List, TextField, useTranslate } from "react-admin";
import { BienTopToolbar } from "../widgets/BienTopToolbar";
import RapatrierProgrammes from "../rapatrierProgrammes/RapatrierProgrammes";

const PostBulkActionButtons = (props: any) => {
    return (
        <Fragment>
            <RapatrierProgrammes {...props} />
        </Fragment>
    );
};

const BienCompteServiceList = (props: any) => {
    const translate = useTranslate();

    return (
        <Fragment>
            <List {...props} bulkActionButtons={<PostBulkActionButtons />} actions={<BienTopToolbar />} empty={false}>
                <Datagrid>
                    <TextField label={translate("lipro.grid_label.reference")} source="rfRefClient" />
                    <TextField label={translate("lipro.grid_label.programme")} source="liNom" />
                    <TextField label={translate("lipro.grid_label.cp")} source="liCodepostal" />
                </Datagrid>
            </List>
        </Fragment>
    );
};

export default BienCompteServiceList;
