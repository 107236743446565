import {
    AutocompleteInput,
    BooleanInput,
    NumberInput,
    required,
    SelectInput,
    TextInput,
    useTranslate,
} from "react-admin";
import React from "react";
import PhaseCommercialeChoices from "../../../model/constants/phaseCommercialeChoices";
import { useStyles } from "../formStyles";
import PaysCPVilleInput from "../widgets/PaysCPVilleInput";
import PeriodeLivraisonInput from "../widgets/PeriodeLivraisonInput";

const InfosPrincipalesTab = (props: any) => {
    const translate = useTranslate();
    const classes = useStyles();
    const { record } = props;

    return (
        <>
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_label.infos_principales.nom_programme")}
                source="liNom"
                validate={required()}
            />
            <TextInput
                className={classes.field}
                label={translate("lipro.edit_label.infos_principales.reference")}
                source="rfRefClient"
            />
            <br />
            <SelectInput
                className={classes.field}
                label={translate("lipro.edit_label.infos_principales.phase_commerciale.title")}
                source="phaseCommerciale"
                translateChoice
                allowEmpty
                choices={PhaseCommercialeChoices}
            />
            <PeriodeLivraisonInput record={record} />
            <br />
            <AutocompleteInput
                className={classes.field}
                label={translate("lipro.edit_label.infos_principales.pays")}
                source="pays"
                optionText="liPays"
                optionValue="liPays"
                choices={[
                    {
                        coLangue: "FR",
                        coPays: "FR",
                        liPays: "FRANCE",
                    },
                ]}
                validate={required()}
            />
            <PaysCPVilleInput required record={record} sourceCP="liCodepostal" sourceVille="ville" />
            <TextInput
                className={classes.largeField}
                label={translate("lipro.edit_label.infos_principales.adresse")}
                source="adresse"
            />
            <br />
            <NumberInput
                className={classes.field}
                label={translate("lipro.edit_label.infos_principales.gps_latitude")}
                source="latitude"
            />
            <NumberInput
                className={classes.field}
                label={translate("lipro.edit_label.infos_principales.gps_longitude")}
                source="longitude"
            />
            <BooleanInput
                className={classes.field}
                label={translate("lipro.edit_label.infos_principales.vente_copro")}
                source="venteCopro"
            />
            <NumberInput
                className={classes.field}
                label={translate("lipro.edit_label.infos_principales.nb_total")}
                source="nbLogements"
            />
            <br />
            <TextInput
                multiline
                rows={10}
                className={classes.largeField}
                label={translate("lipro.edit_label.infos_principales.descriptif")}
                source="descriptifWeb"
                validate={required()}
            />
        </>
    );
};

export default InfosPrincipalesTab;
