import { SessionUser } from "../utils/SessionUser";
import { RestRoutes } from "../constants/restRoutes";
import UserVO from "../model/UserVO";
import { defaultCompteBeneficiaireClient } from "../fixtures/defaultCompteBeneficiaireClient";
import ClientVO from "../model/ClientVO";
import { doFetchSSO } from "./doFetch";

export class SSOProvider {
    private sessionUser: SessionUser;

    constructor(sessionUser: SessionUser) {
        this.sessionUser = sessionUser;
    }

    async fetchClientByIdSSO(idSSO: string, errorFallback: (status: number) => void) {
        let userVO: UserVO;
        let token: string = idSSO;
        const route = RestRoutes.getClientByIdSSO;
        if (process.env.REACT_APP_DEMO_USER_NAME && token === process.env.REACT_APP_DEMO_USER_NAME) {
            const compteUtilisateur = defaultCompteBeneficiaireClient;
            userVO = new UserVO({
                nom: compteUtilisateur.liNom,
                token: process.env.REACT_APP_DEMO_USER_JWT_TOKEN,
                typeUser: "CLIENT",
                code: compteUtilisateur.coClient,
                client: new ClientVO(compteUtilisateur),
            });
        } else {
            userVO = await doFetchSSO(route, token, errorFallback);
        }
        return userVO;
    }
}
