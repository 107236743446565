import * as React from "react";
import { Fragment, useContext, useEffect, useState } from "react";
import {
    BulkDeleteButton,
    Datagrid,
    DateField,
    EditButton,
    Filter,
    List,
    NumberField,
    Pagination,
    TextField,
    TextInput,
    useListContext,
    useTranslate,
} from "react-admin";
import DiffuserProgrammes from "../diffuserProgrammes/DiffuserProgrammes";
import DesactiverProgrammes from "../desactiverProgrammes/DesactiverProgrammes";
import { StatutField } from "./widgets/StatutField";
import { PLField } from "./widgets/PLField";
import { VideoField } from "./widgets/VideoField";
import { BienTopToolbar } from "../widgets/BienTopToolbar";
import { OrigineField } from "./widgets/OrigineField";
import { ProgrammeField } from "./widgets/ProgrammeField";
import { Button, Divider, Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import ApartmentIcon from "@material-ui/icons/Apartment";
import { UserContext } from "../../utils/SessionUser";

const useStyles = makeStyles(() => ({
    numberColumn: {
        textAlign: "right",
    },
}));

const BiensBulkActionButtons = (props: any) => {
    return (
        <Fragment>
            <BulkDeleteButton {...props} undoable={false} />
            <Divider
                orientation="vertical"
                flexItem
                style={{
                    margin: "-12px 20px 0 20px",
                    color: "grey",
                    backgroundColor: "grey",
                }}
            />
            <DiffuserProgrammes {...props} />
            <DesactiverProgrammes {...props} />
        </Fragment>
    );
};

const BienPagination = (props: any) => {
    const { filterValues, total, setPerPage } = useListContext();
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState<number[]>([10, 25, 50, 100]);
    useEffect(() => {
        if (Object.keys(filterValues).length !== 0) {
            setRowsPerPageOptions([total]);
            setPerPage(total);
        } else {
            setRowsPerPageOptions([10, 25, 50, 100]);
            setPerPage(50);
        }
    }, [total, setRowsPerPageOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    return <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />;
};

const LinkToLots = (props: { record?: any }) => {
    const sessionUserContext = useContext(UserContext);
    const translate = useTranslate();
    return props.record ? (
        <Tooltip title={translate("lipro.action.acceder_lots")}>
            <Button
                color="primary"
                component={Link}
                to={{ pathname: "/lots" }}
                onClick={() => sessionUserContext.setIdProgramme(props.record.id)}
            >
                <ApartmentIcon />
            </Button>
        </Tooltip>
    ) : null;
};

const BienFilter = (props: any) => {
    const translate = useTranslate();
    return (
        <Grid container direction="row" justify="space-between" alignItems="baseline">
            <Typography variant="h5">{translate("lipro.grid_label.title")}</Typography>
            <Filter {...props}>
                <TextInput
                    label={translate("lipro.action.rechercher_programmes")}
                    source="q"
                    alwaysOn
                    style={{ width: "30vw", marginBottom: 0 }}
                />
            </Filter>
        </Grid>
    );
};

const BienList = (props: any) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Fragment>
            <List
                {...props}
                bulkActionButtons={<BiensBulkActionButtons />}
                actions={<BienTopToolbar />}
                filters={<BienFilter />}
                empty={false}
                pagination={<BienPagination />}
                perPage={50}
            >
                <Datagrid>
                    <TextField label={translate("lipro.grid_label.reference")} source="rfRefClient" sortable={false} />
                    <ProgrammeField label={translate("lipro.grid_label.programme")} source="liNom" sortable={false} />
                    <TextField label={translate("lipro.grid_label.cp")} source="liCodepostal" sortable={false} />
                    <NumberField
                        label={translate("lipro.grid_label.photos")}
                        source="nbPhotosWeb"
                        sortable={false}
                        headerClassName={classes.numberColumn}
                    />
                    <DateField label={translate("lipro.grid_label.maj")} source="dtModif" sortable={false} />
                    <StatutField label={translate("lipro.grid_label.li")} source="coStatutLi" sortable={false} />
                    <StatutField label={translate("lipro.grid_label.li9")} source="coStatutLi9" sortable={false} />
                    <PLField label={translate("lipro.grid_label.pl")} source="flagP5" sortable={false} />
                    <VideoField label={translate("lipro.grid_label.videos")} source="videoCoStatut" sortable={false} />
                    <OrigineField label={translate("lipro.grid_label.origine")} source="coSource" sortable={false} />
                    <LinkToLots />
                    <EditButton label={""} />
                </Datagrid>
            </List>
        </Fragment>
    );
};

export default BienList;
